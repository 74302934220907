import React, { useEffect, useState } from 'react';

import Container from '../visual/Container';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getItem, deleteDispatch } from '../util/Functions';
import Button from '../visual/Button';

const View = () => {

    let { id } = useParams();
    let navigate = useNavigate();
    let location = useLocation();

    const [item, setItem] = useState(null);

    useEffect(() => {

        //check if user is an admin
        if (localStorage.getItem('raqd_role') !== 'administrator') {
            navigate('/dashboard/user');
        }

        getItem(id, 'dispatch').then(res => {
            let data = res.data.acf;
            setItem(data);
        }).catch(error => {
            console.log(error);
        })
    }, [])


    const capitaliseTitle = (title) => {
        let string = title.charAt(0).toUpperCase() + title.slice(1);
        return string.replace('_', " ");
    }

    const goBack = () => {
        navigate(-1);
    }

    const removeItem = () => {
        let proceed = window.confirm('Are you sure you wish to delete racq item: ' + item.racq_number + '?');

        if (!proceed) return;

        deleteDispatch(id).then(res => {
            console.log('deleted res', res);
            navigate('/dashboard/archive')
        }).catch(error => {
            console.error(error);
        })
    }

    return (
        <Container style={container}>
            <h2 style={h2}>View</h2>
            <hr

                style={{
                    margin: '1%',
                }}
            />
            <div style={{ padding: '1%' }}>
                {
                    item && Object.keys(item).map((key, index) => {

                        if (item[key]) {
                            return (
                                <div style={itemContainer} key={index}>
                                    <p style={heading}>{capitaliseTitle(key)}:</p>
                                    <p style={p}>{item[key] === true ? 'TRUE' : item[key]}</p>
                                </div>
                            )
                        }

                    })
                }
                <div style={btnContainer}>
                    <Button btnstyle={{ marginRight: '0.3rem' }} onClick={goBack}>Back</Button>
                    <Button onClick={removeItem}>Delete</Button>
                </div>

            </div>


        </Container>
    );
}

const container = {
    flexDirection: 'column',
    width: '90%',
    alignSelf: 'center',
    padding: '1%',
    height: '100%',
    overflow: 'hidden',
    boxSizing: 'border-box',
    overflow: 'auto'
}

const h2 = {
    margin: '1%',
    color: 'var(--main-color)'
}

const heading = {
    fontWeight: 'bold',
    fontSize: '1.1rem'
}

const p = {
    fontSize: '1rem',
    color: 'var(--main-text-color)'
}

const itemContainer = {
    padding: '0.2rem'
}

const btnContainer = {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '600px',
    marginTop: '0.3rem'
}

export default View;