import React, { useEffect, useState } from 'react';

import { getItem } from '../util/Functions';

import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';

import { useParams, useNavigate } from 'react-router-dom';
import DockectAndSplit from './DocketAndSlip';
import { createBrowserHistory } from 'history';

const PrintDocketAndSlip = () => {

    let { id, to } = useParams();
    let history = createBrowserHistory();
    let navigate = useNavigate();

    const [racq, setRacq] = useState('');
    const [client, setClient] = useState('');
    const [reference, setReference] = useState('');
    const [bay, setBay] = useState('');
    const [invoice, setInvoice] = useState('');
    const [date, setDate] = useState('');
    const [orderNo, setOrderNo] = useState(null)
    const [srNo, setSrNo] = useState(null);
    const [apple, setApple] = useState(false);

    useEffect(() => {


        getItem(id, 'dispatch').then(res => {

            setRacq(res.data.acf.racq_number);
            setReference(res.data.acf.reference)
            setClient(res.data.acf.client);
            setInvoice(res.data.acf.invoice_number);
            setDate(res.data.acf.date);
            setBay(res.data.acf.bay_number);
            if (res.data.acf.order_number) setOrderNo(res.data.acf.order_number);
            if (res.data.acf.sr_number) setSrNo(res.data.acf.sr_number)

            console.log('res data', res.data);

        }).catch(error => {
            console.error(error);
        })

        if (getBrowser() === "Apple Safari") {
            setApple(true);
        }

        return history.listen(({ location, action }) => {
            if (action === 'POP') {
                navigate('/');
            }
        })



    }, []);

    const getBrowser = () => {

        var sBrowser, sUsrAg = navigator.userAgent;

        // The order matters here, and this may report false positives for unlisted browsers.

        if (sUsrAg.indexOf("Firefox") > -1) {
            sBrowser = "Mozilla Firefox";
            // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
        } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
            sBrowser = "Samsung Internet";
            // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
        } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
            sBrowser = "Opera";
            // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
        } else if (sUsrAg.indexOf("Trident") > -1) {
            sBrowser = "Microsoft Internet Explorer";
            // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
        } else if (sUsrAg.indexOf("Edge") > -1) {
            sBrowser = "Microsoft Edge";
            // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
        } else if (sUsrAg.indexOf("Chrome") > -1) {
            sBrowser = "Google Chrome or Chromium";
            // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
        } else if (sUsrAg.indexOf("Safari") > -1) {
            sBrowser = "Apple Safari";
            // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
        } else {
            sBrowser = "unknown";
        }

        console.log('Browser:', sBrowser);
        return sBrowser;

    }

    return (
        <div style={apple ? linkContainer : pageContainer}>

            {apple && <PDFDownloadLink
                document={
                    <DockectAndSplit

                        racq={racq}
                        reference={reference}
                        client={client}
                        invoice={invoice}
                        bay={bay}
                        date={date}
                        orderNo={orderNo}
                        srNo={srNo}
                        to={to}
                    />
                }
                fileName={racq + "pick slip.pdf"}>
                {({ blob, url, loading, error }) => (loading ? 'loading' : 'download')}
            </PDFDownloadLink>}
            {!apple && <PDFViewer style={{ height: '100vh' }} >
                <DockectAndSplit

                    racq={racq}
                    reference={reference}
                    client={client}
                    invoice={invoice}
                    bay={bay}
                    date={date}
                    orderNo={orderNo}
                    srNo={srNo}
                    to={to}
                />
            </PDFViewer>}
        </div>
    );
}

const pageContainer = {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
}

const linkContainer = {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
}

export default PrintDocketAndSlip;