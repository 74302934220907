import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { RecoilRoot } from 'recoil';


import Login from './screens/Login';
import Dashboard from './screens/Dashboard';
import User from './screens/User';
import Admin from './screens/Admin';
import Add from './screens/Add';
import Dispatch from './screens/Dispatch';
import Edit from './screens/Edit';
import Archive from './screens/Archive';
import View from './screens/View';
import UserDispatch from './screens/UserDispatch';
import UserArchive from './screens/UserArchive';

import PrintPage from './components/PrintPage';
import PrintPage2 from './components/PrintPage2';
import PrintPickSlip from './components/PrintPickSlip';
import PrintDocketAndSlip from './components/PrintDocketAndSlip';

function App() {
  return (
    <RecoilRoot>
      <Router>
        <div className="App">
          <Routes>
            <Route exact path={"/"} element={<Login />} />
            <Route path={"/login"} element={<Login />} />

            <Route exact path="/dashboard" element={<Dashboard />} >
              <Route path="user" element={<User />} />
              <Route path="userdispatch" element={<UserDispatch />} />
              <Route path="userarchive" element={<UserArchive />} />
              <Route path="admin" element={<Admin />} />
              <Route path="new" element={<Add />} />
              <Route path="new/:copyId" element={<Add />} />
              <Route path="dispatch" element={<Dispatch />} />
              <Route path="archive" element={<Archive />} />
              <Route path="admin/:id" element={<Edit />} />
              <Route path="dispatch/:id" element={<Edit />} />
              <Route path="view/:id" element={<View />} />
            </Route>
            <Route exact path="/print/:id/:to" element={<PrintPage />} />
            <Route exact path="print2/:id/:bar" element={<PrintPage2 />} />
            <Route exact path="/pickslip/:id" element={<PrintPickSlip />} />
            <Route exact path="/docketandslip/:id/:to" element={<PrintDocketAndSlip />} />
          </Routes>
        </div>
      </Router>
    </RecoilRoot>
  );
}

export default App;
