import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, } from 'react-router-dom';
import Radium from 'radium';
import '../css/component.css';

import Container from '../visual/Container';
import Select from '../visual/Select';
import Textbox from '../visual/Textbox';

import BarLoader from 'react-spinners/BarLoader';
import BeatLoader from 'react-spinners/BeatLoader';
import { CSVLink } from 'react-csv';

import { deleteDispatch, getData, getClientList, addInventoryItems } from '../util/Functions';
import '../css/Admin.css';
import useSWR, { useSWRConfig } from 'swr'
import { Link } from 'react-router-dom';

import moment from 'moment';

const Archive = () => {

    const [orderedItems, setOrderedItems] = useState([]);
    const [clientList, setClientList] = useState(['All']);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const [selectedClient, setSelectedClient] = useState('');
    const [selectedOrder, setSelectedOrder] = useState('Date');
    const [search, setSearch] = useState('');
    const [searchBy, setSearchBy] = useState('All');

    const { data: dispatch, error: dispatchError, isValidating } = useSWR('/wp-json/wp/v2/dispatch', (url) => getData(url).then(r => r));
    const { data: clients, error: clientsError } = useSWR('clients', (url) => getClientList(url, 'keys').then(r => r));
    const { mutate } = useSWRConfig()

    const orderby = [
        'Racq #',
        'Client',
        'Reference',
        'Bay',
        'Date',
        'Type',
        'Invoice',
    ];

    useEffect(() => {

        //check if user is an admin
        if (localStorage.getItem('raqd_role') != 'administrator') {
            navigate('/dashboard/user');
        }

        let searchData = sessionStorage.getItem('racq_search_dispatch');
        if (searchData !== null) {
            searchData = JSON.parse(searchData);
            setSelectedClient(searchData.selectedClient);
            setSelectedOrder(searchData.selectedOrder);
            setSearch(searchData.search);
            setSearchBy(searchData.searchBy);
        }

    }, [])

    useEffect(() => {
        reOrder();
    }, [selectedClient, selectedOrder]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            console.log(search)
            reOrder();
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [search])

    useEffect(() => {
        if (dispatch) {
            reOrder();
        }
        if (dispatchError) {
            console.log('dispatchError', dispatchError);
            alert('Network Error:: Please refresh or contact administrator');
        }
    }, [dispatch])

    useEffect(() => {
        if (clients) {
            // console.log('clients', clients);
            setClientList(['All', ...clients.sort()]);
        }
        if (clientsError) {
            console.log('clientsError', clientsError);
            alert('Network Error:: Please refresh or contact administrator');
        }
    }, [clients])

    const reOrder = () => {
        //save search to localStorage
        let searchData = {
            search: search,
            searchBy: searchBy,
            selectedClient: selectedClient,
            selectedOrder: selectedOrder,
        }

        sessionStorage.setItem('racq_search_dispatch', JSON.stringify(searchData));

        let filterData = {
            search: search,
            list: dispatch,
            searchBy: searchBy,
            selectedClient: selectedClient,
            selectedOrder: selectedOrder,
        }



        filter(filterData).then(data => {
            setOrderedItems(data);
        }).catch(error => {
            console.log(error);
        })

        return;

    }

    const deleteItem = (item) => {
        let proceed = window.confirm('Are you sure you wish to delete racq item: ' + item.racq_number + '?');

        if (!proceed) return;

        deleteDispatch(item.id).then(res => {
            console.log('deleted res', res);
            mutate('/wp-json/wp/v2/dispatch');
            setSelectedClient('All');
        }).catch(error => {
            console.error(error);
        })

    }

    const filter = async (data) => {

        var search = data.search;
        var list = data.list;
        var searchBy = data.searchBy;
        var selectedClient = data.selectedClient;
        var selectedOrder = data.selectedOrder;

        let promise = new Promise((resolve, reject) => {
            if (!list) {
                reject('list not found');
            }

            //Apply Client filter
            if (selectedClient != 'All' && selectedClient != '') {
                list = list.filter(x => x.client == selectedClient);
            }

            //Apply orderby
            list.sort((a, b) => {
                switch (selectedOrder) {
                    case '':
                        if (a.racq_number < b.racq_number) return -1;
                        if (a.racq_number > b.racq_number) return 1;
                        return 0;
                    case 'Racq #':
                        if (a.racq_number < b.racq_number) return -1;
                        if (a.racq_number > b.racq_number) return 1;
                        return 0;
                    case 'Client':
                        if (a.client < b.client) return -1;
                        if (a.client > b.client) return 1;
                        return 0;
                    case 'Reference':
                        if (a.reference < b.reference) return -1;
                        if (a.reference > b.reference) return 1;
                        return 0;
                    case 'Bay':
                        if (a.bay_number < b.bay_number) return -1;
                        if (a.bay_number > b.bay_number) return 1;
                        return 0;
                    case 'Date':
                        if (moment(a.date, 'DD/MM/YYYY').valueOf() > moment(b.date, 'DD/MM/YYYY').valueOf()) return -1;
                        if (moment(a.date, 'DD/MM/YYYY').valueOf() < moment(b.date, 'DD/MM/YYYY').valueOf()) return 1;
                        return 0;
                    case 'Order #':
                        if (a.order_number < b.order_number) return -1;
                        if (a.order_number > b.order_number) return 1;
                        return 0;
                    case 'SR #':
                        if (a.sr_number < b.sr_number) return -1;
                        if (a.sr_number > b.sr_number) return 1;
                        return 0;
                    case 'Type':
                        if (a.type < b.type) return -1;
                        if (a.type > b.type) return 1;
                        return 0;
                    case 'Invoice':
                        if (a.invoice_number < b.invoice_number) return -1;
                        if (a.invoice_number > b.invoice_number) return 1;
                        return 0;
                }
            })


            switch (searchBy.trim()) {
                case '':
                    break;
                case 'Racq #':
                    list = list.filter(x => x.racq_number.toLowerCase().includes(search.toLowerCase()))
                    break;
                case 'Client':
                    list = list.filter(x => x.client.toLowerCase().includes(search.toLowerCase()))
                    break;
                case 'Reference':
                    list = list.filter(x => x.reference.toLowerCase().includes(search.toLowerCase()) ||
                        x.barcode.toLowerCase().includes(search.toLowerCase()))
                    break;
                case 'Bay':
                    list = list.filter(x => x.bay_number.toLowerCase().includes(search.toLowerCase()))
                    break;
                case 'Recieved':
                    list = list.filter(x => x.received.toLowerCase().includes(search.toLowerCase()))
                    break;
                case 'Order #':
                    list = list.filter(x => x.order_number.toLowerCase().includes(search.toLowerCase()))
                    break;
                case 'Design':
                    list = list.filter(x => x.design.toLowerCase().includes(search.toLowerCase()))
                    break;
                case 'SR #':
                    list = list.filter(x => x.sr_number.toLowerCase().includes(search.toLowerCase()))
                    break;
                case 'Type':
                    list = list.filter(x => x.type.toLowerCase().includes(search.toLowerCase()))
                    break;
                case 'Invoice':
                    list = list.filter(x => x.invoice_number.toLowerCase().includes(search.toLowerCase()))
                    break;
            }

            //Search by all 
            if (searchBy.trim() === 'All') {
                list = list.filter(x => {
                    if (x.racq_number?.toLowerCase()?.includes(search.toLowerCase()) ||
                        x.client?.toLowerCase()?.includes(search.toLowerCase()) ||
                        x.reference?.toLowerCase()?.includes(search.toLowerCase()) ||
                        x.barcode?.toLowerCase()?.includes(search.toLowerCase()) ||
                        x.bay_number?.toLowerCase()?.includes(search.toLowerCase()) ||
                        x.received?.toLowerCase()?.includes(search.toLowerCase()) ||
                        x.order_number?.toLowerCase()?.includes(search.toLowerCase()) ||
                        x.sr_number?.toLowerCase()?.includes(search.toLowerCase()) ||
                        x.design?.toLowerCase()?.includes(search.toLowerCase()) ||
                        x.type?.toLowerCase()?.includes(search.toLowerCase()) ||
                        x.invoice_number?.toLowerCase()?.includes(search.toLowerCase())) {
                        return true
                    } else {
                        return false;
                    }
                })

            }

            //remove all none archive
            list = list.filter(x => x.archive);

            resolve(list);
        })

        return promise;

    }

    const exportCSV = () => {
        if (orderedItems && orderedItems.length > 0) {
            return [...orderedItems];
        } else {
            return [];
        }
    }

    const returnItem = (item) => {
        let proceed = window.confirm('Are you sure you wish to return racq item: ' + item.racq_number + ' to inventory?');

        if (!proceed) return;
        addInventoryItems(item).then(res => {
            deleteDispatch(item.id).then(res => {
                console.log('deleted res', res);
                mutate('/wp-json/wp/v2/dispatch');
                setSelectedClient('All');
            }).catch(error => {
                console.error(error);
            })
        }).catch(error => {
            console.error(error);
        })
    }

    const generateSummary = () => {
        //console.log('summary: ', orderedItems);
        let types = {};
        for (let item of orderedItems) {
            if (types.hasOwnProperty(item.client)) {
                types[item.client].push(item.type);
            } else {
                let key = item.client;
                let value = [item.type];
                types[key] = value;
            }
        }

        let summary = [];

        for (const [key, value] of Object.entries(types)) {
            let row = {
                client: key
            }
            for (let t of value) {
                if (row.hasOwnProperty(t)) {
                    row[t] = row[t] + 1;
                } else {
                    row[t] = 1;
                }
            }
            row['total'] = value.length;
            summary.push(row);
        }

        let headers = [
            { label: 'Client', key: 'client' },
            { label: 'Carpets', key: 'carpet' },
            { label: 'Rugs', key: 'rug' },
            { label: 'Pallets', key: 'pallet' },
            { label: 'Entrance Rugs', key: 'entrance rug' },
            { label: 'Other', key: 'other' },
            { label: 'Total', key: 'total' },
        ]

        return [summary, headers];

    }

    return (
        <Container
            style={container}
        >

            <h2
                style={{
                    margin: '1%',
                    color: 'var(--main-color)'
                }}
            >
                Dispatched
            </h2>
            <hr

                style={{
                    margin: '1%',
                }}
            />
            <div
                style={filters}
            >
                <div style={secondaryContainer}>
                    <Select
                        label="Client"
                        list={clientList}
                        id="client-filter"
                        name="client-filter"
                        value={selectedClient}
                        onChange={e => setSelectedClient(e.target.value)}
                        containerstyle={filterInput}
                    />
                    <Select
                        label="Order by"
                        list={orderby}
                        id="orderby"
                        name="orderby"
                        value={selectedOrder}
                        onChange={e => setSelectedOrder(e.target.value)}
                        containerstyle={filterInput}
                    />
                </div>

                <div style={secondaryContainer}>
                    <Select
                        label="Search by"
                        list={['All', ...orderby]}
                        id="search-by"
                        value={searchBy}
                        onChange={event => setSearchBy(event.target.value)}
                        containerstyle={filterInput}
                    />
                    <Textbox
                        type="text"
                        label="Search"
                        id="ref-search"
                        name="ref-search"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        txtstyle={filterInput}
                    />
                </div>


            </div>

            <div style={btnContainer}>
                <CSVLink data={exportCSV()} className='export-link' >Export</CSVLink>
                <CSVLink
                    data={generateSummary()[0]}
                    headers={generateSummary()[1]}
                    filename='summary-report.csv'
                    className='export-link'
                >
                    Summary
                </CSVLink>
            </div>

            <div style={count}>
                <p>Showing {orderedItems ? orderedItems.length : 0} {orderedItems && orderedItems.length == 1 ? 'item' : 'items'}</p>
                <BeatLoader size={10} color="#0571cb" loading={isValidating} />
            </div>
            <div
                style={tableContainer}
            >
                {loading ? <BarLoader width="100%" color="#0571cb" loading={true} /> : <table
                    style={table}
                >
                    <thead

                    >
                        <td style={th}>Client</td>
                        <td style={th}>Racq #</td>
                        <td style={th}>Order #</td>
                        <td style={th}>Reference</td>
                        <td style={th}>Size</td>
                        <td style={th}>Design / Colour</td>
                        <td style={th}>Invoice</td>
                        <td style={th}>Date</td>
                        <td style={th}>Type</td>
                    </thead>

                    <tbody>
                        {
                            orderedItems.map((item, index) => {

                                return (
                                    <tr style={index % 2 == 1 ? tr : tr2} key={index}>
                                        <td style={td}>
                                            {item.client}
                                            <div
                                                style={optionContainer}
                                            >
                                                <Link to={`../view/${item.id}`} className='admin-option' >View</Link>
                                                <p style={option2}>|</p>
                                                <Link to={`../new/${item.id}`} className='admin-option' >Copy</Link>

                                            </div>
                                        </td>
                                        <td style={td}>{item.racq_number} </td>
                                        <td style={td}>{item.order_number}</td>
                                        <td style={td}>{item.reference}</td>
                                        <td style={td}>{item.size}</td>
                                        <td style={td}>{item.design}</td>
                                        <td style={td}>{item.invoice_number}</td>
                                        <td style={td}>{item.date}</td>
                                        <td style={td}>{item.type}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>


                </table>}
            </div>
        </Container>
    );
}

const container = {
    flexDirection: 'column',
    width: '90%',
    alignSelf: 'center',
    padding: '1%',
    height: '100%',
    overflow: 'hidden',
    boxSizing: 'border-box'
}

const filters = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1%'
}

const count = {
    display: 'flex',
    flexDirection: 'row',
    padding: '1% 2% 1% 2%',
    fontSize: '0.75rem',
    fontWeight: 'bold'
}

const tableContainer = {
    display: 'flex',
    maxWidth: '100%',
    overflow: 'auto',
    borderRadius: '10px',
}

const table = {
    width: '100%',
    maxWidth: '100%',
    borderCollapse: 'collapse',
    borderRadius: '10px',
    backgroundColor: 'white',
    borderSpacing: '1',
    position: 'relative'
}

const th = {
    padding: '0.75rem 0 0.75rem 0.75rem',
    color: 'var(--alt-text-color)',
    backgroundColor: 'var(--main-color)',
    position: 'sticky',
    top: 0,
}

const tr = {
    cursor: 'pointer',
}

const tr2 = {
    cursor: 'pointer',
    backgroundColor: '#e2e2e2',
}

const td = {
    padding: '0.5rem'
}

const optionContainer = {
    display: 'flex',
    flexDirection: 'row',
    padding: '0.3rem 0.3rem 0.3rem 0px',
    minWidth: '150px'
}

const option = {
    fontSize: '0.7rem',
    cursor: 'pointer',
    marginRight: '0.3rem',
    ':hover': {
        color: 'var(--main-color)',
        textDecoration: 'underline',
    }
}

const option2 = {
    fontSize: '0.7rem',
    marginRight: '0.3rem',
}

const filterInput = {
    padding: '0.3rem 0.3rem 0 0.3rem',
    width: '200px'
}

const secondaryContainer = {

}

const btnContainer = {
    paddingLeft: '2%',
    display: 'flex',
    flexDirection: 'row',
    columnGap: '0.5rem'
}

export default Radium(Archive);