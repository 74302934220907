import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

import Logo from '../images/RACQ_Black_Horizontal.png';



const DocketAndSlip = ({ racq, reference, client, invoice, date, bay, srNo, orderNo, to }) => {

    // Create styles
    const page1 = StyleSheet.create({
        page: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
        },
        container: {
            minWidth: '100%',
            display: 'flex',
            flexDirection: 'row',
        },
        innerContainer: {
            justifyContent: 'center',
            alignItems: 'center'
        },
        heading: {
            display: 'flex',
            width: '400px',
            height: '150px',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white'
        },
        details: {
            marginTop: '10px',
            width: '100%',
            paddingLeft: '15px',
        },
        h1: {
            fontSize: 64
        },
        h2: {
            fontSize: 20,
            textDecoration: 'underline',
            fontWeight: 'bold'
        },
        p: {
            padding: '2px',
            fontSize: 17,
        },
        smallP: {
            fontWeight: 'bold',
            fontSize: 15,
            padding: '2px'
        }
    });

    // Create styles
    const page2 = StyleSheet.create({
        page: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white',
        },
        container: {
            minWidth: '100%',
            height: '30%',
            display: 'flex',
            flexDirection: 'row',
        },
        innerContainer: {
            width: '50%',
            justifyContent: 'center',
            alignItems: 'center'
        },
        smallbox: {
            border: '1px solid black',
            padding: '5px'
        },
        heading: {
            display: 'flex',
            width: '400px',
            height: '150px',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white'
        },
        details: {
            marginTop: '10px',
            width: '100%',
            paddingLeft: '60px'
        },
        h1: {
            fontSize: 64
        },
        h2: {
            fontSize: 20,
            textDecoration: 'underline',
            fontWeight: 'bold'
        },
        p: {
            padding: '1px',
            fontSize: 15,
        },
        smallP: {
            fontWeight: 'bold',
            fontSize: 15,
            padding: '2px'
        }
    });



    return (
        <Document size="A4">
            <Page style={page1.page}>
                <View style={page1.container}>

                    <View style={{ ...page1.innerContainer }}>
                        <Image style={{ width: '200' }} src={Logo} />
                        <Text style={page1.h2}>
                            Picking Slip
                        </Text>

                        <View style={page1.details}>
                            <Text style={page1.p}>
                                Racq #: {racq}
                            </Text>
                            <Text style={page1.p}>
                                Invoice #: {invoice}
                            </Text>
                            <Text style={page1.p}>
                                Bay #: {bay}
                            </Text>
                            <Text style={page1.p}>
                                Date: {date}
                            </Text>
                            <Text style={page1.p}>
                                Client: {client}
                            </Text>
                            <Text style={page1.p} >
                                Reference: {reference}
                            </Text>
                            <Text style={page1.p} >
                                SR Number: {srNo}
                            </Text>
                            <Text style={page1.p} >
                                Order Number: {orderNo}
                            </Text>
                        </View>

                    </View>

                </View>
            </Page>

            <Page style={page2.page}>
                <View style={page2.container}>
                    <View style={page2.innerContainer}>
                        <View style={page2.smallbox}>
                            <Text style={page2.smallP}>
                                BAY#: {bay}
                            </Text>
                            <Text style={page2.smallP}>
                                RACQ#: {racq}
                            </Text>
                        </View>

                    </View>
                    <View style={{ ...page2.innerContainer, borderTop: '1px solid black', borderBottom: '1px solid black' }}>
                        <Image style={{ width: '200' }} src={Logo} />
                        <Text style={page2.h2}>
                            DISPATCH ORDER
                        </Text>

                        <View style={page2.details}>
                            <Text style={page2.p}>
                                Date: {date}
                            </Text>
                            <Text style={page2.p}>
                                Client: {client}
                            </Text>
                            <Text style={page2.p} >
                                Reference: {reference}
                            </Text>
                            <Text style={page2.p}>
                                To: {to}
                            </Text>
                        </View>

                    </View>

                </View>
            </Page>
        </Document>
    );
}

export default DocketAndSlip;