import React, { useState, useEffect } from 'react';
import '../css/Sidebar.css';

import { Link } from 'react-router-dom';
import { IoLogOut } from 'react-icons/io5';
import { MdInventory2, MdAccountCircle } from 'react-icons/md';
import { FaTruck, FaArchive } from 'react-icons/fa'
import { AiOutlineFileAdd } from 'react-icons/ai';
import { Auth } from '../State';
import { useSetRecoilState } from 'recoil';

const Sidebar = (props) => {

    const setAuth = useSetRecoilState(Auth);
    const [permission, setPermission] = useState('user');

    let transform = props.visible ? 'translateX(0px)' : 'translateX(-200%)'

    const container = {
        backgroundColor: 'var(--alt-bg-color)',
        borderRight: 'var(--main-border)',
        width: '300px',
        height: '100%',
        maxWidth: '45%',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1,
        transform: transform,
        transition: 'transform 400ms ease',
        fontFamily: 'var(--secondary-font)'
    }

    const links = [
        {
            icon: <MdInventory2 className="sidebar-icon" />,
            text: 'Inventory',
            link: 'admin',
            permission: 'administrator'
        },
        {
            icon: <FaTruck className="sidebar-icon" />,
            text: 'Picked and Ready',
            link: 'dispatch',
            permission: 'administrator'
        },
        {
            icon: <FaArchive className="sidebar-icon" />,
            text: 'Dispatched',
            link: 'archive',
            permission: 'administrator'
        },
        {
            icon: <AiOutlineFileAdd className="sidebar-icon" />,
            text: 'Add New',
            link: 'new',
            permission: 'administrator'
        },
        {
            icon: <MdAccountCircle className="sidebar-icon" />,
            text: 'My Inventory',
            link: 'user',
            permission: 'user'
        },
        {
            icon: <FaTruck className="sidebar-icon" />,
            text: 'Picked and Ready',
            link: 'userdispatch',
            permission: 'user'
        },
        {
            icon: <FaArchive className="sidebar-icon" />,
            text: 'Dispatched',
            link: 'userarchive',
            permission: 'user'
        },

    ];

    useEffect(() => {
        if (localStorage.getItem('raqd_role') == 'administrator') {
            setPermission('administrator');
        }
    }, [])

    const logout = () => {
        localStorage.removeItem('raqd_token');
        localStorage.removeItem('raqd_username');
        localStorage.removeItem('raqd_role');
        setAuth(false);
    }

    return (
        <div
            style={container}
        >
            <div
                style={banner}
                className="closeButton"
                onClick={props.onClose}
            >
                <h2>Menu</h2>
                <p>Welcome, {localStorage.getItem('raqd_username')}</p>
            </div>

            {
                links.map((item, index) => {

                    if (item.permission !== permission) return null;

                    return (
                        <div
                            className='sidebar-link'
                            key={index}
                        >
                            {item.icon}
                            <Link
                                style={linkText}
                                to={item.link}
                                onClick={props.onClose}
                            >
                                {item.text}
                            </Link>
                        </div>
                    )
                })
            }

            <div
                style={{ cursor: 'pointer' }}
                key="logout"
                onClick={logout}
                className="sidebar-link"
            >
                <IoLogOut
                    className="sidebar-icon"
                />
                <p
                    style={linkText}
                >
                    Log Out
                </p>
            </div>



        </div>
    );
}

const banner = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: 'var(--main-border)',
    height: '75px',
    cursor: 'pointer'
}

const linkText = {
    textDecoration: 'none',
    color: 'var(--main-text-color)',
    width: '100%',
    marginLeft: '1.5rem'
}



export default Sidebar;