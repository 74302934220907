import React, { useState, useEffect } from 'react';

import { useNavigate, useParams, useLocation } from 'react-router-dom';
import {
    getItem,
    getClientList,
    saveItem,
    deleteInventory,
    convertDateToNzFormat,
    convertNzDateToReadable,
    getBrowser,
    deleteDispatch
} from '../util/Functions';
import moment from 'moment';
import Container from '../visual/Container';
import Textbox from '../visual/Textbox';
import Button from '../visual/Button';
import Modal from '../visual/Modal'

import BarLoader from 'react-spinners/BarLoader';



const Edit = () => {

    const [clientList, setClientList] = useState(['Award', 'Nodi', 'Sallee', 'SF Design', 'Luxe']);
    const [client, setClient] = useState('');
    const [reference, setReference] = useState('');
    const [bay, setBay] = useState('');
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
    const [notes, setNotes] = useState('');
    const [type, setType] = useState('');
    const [racq, setRacq] = useState('');
    const [barcode, setBarcode] = useState('')
    const [design, setDesign] = useState('');
    const [size, setSize] = useState('');
    const [orderNo, setOrderNo] = useState('');
    const [srNo, setSrNo] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const [visible, setVisible] = useState(false);
    const [printItem, setPrintItem] = useState(null);
    const [to, setTo] = useState('');

    let navigate = useNavigate();
    let { id } = useParams();
    let { pathname } = useLocation();

    useEffect(() => {

        //check if user is an admin
        if (localStorage.getItem('raqd_role') != 'administrator') {
            navigate('/dashboard/user');
        }

        getClientList('clients', 'keys').then(data => {
            let newList = [...clientList];
            for (let c of data) {
                if (!newList.includes(c)) {
                    newList.push(c);
                }
            }
            setClientList(newList);
        });

        let lib = '';
        if (pathname.includes('admin')) {
            lib = 'stock';
        }
        if (pathname.includes('dispatch')) {
            lib = 'dispatch';
        }
        getItem(id, lib).then(res => {
            console.log('edit', res.data);
            setClient(res.data.acf.client);
            setReference(res.data.acf.reference);
            setBay(res.data.acf.bay_number);
            setNotes(res.data.acf.notes);
            setType(res.data.acf.type);
            setRacq(res.data.acf.racq_number);
            setBarcode(res.data.acf.barcode);
            setDesign(res.data.acf.design);
            setSize(res.data.acf.size);
            setOrderNo(res.data.acf.order_number);
            setSrNo(res.data.acf.sr_number);
            setInvoiceNumber(res.data.acf.invoice_number);
            if (lib === 'stock') setDate(convertNzDateToReadable(res.data.acf.received));
            if (lib === 'dispatch') setDate(convertNzDateToReadable(res.data.acf.date));
        }).catch(error => {
            console.log('failed to retrieve data');
            console.error(error);
        })



    }, [])

    useEffect(() => {
        if (printItem !== null) {
            setVisible(true);
        }
    }, [printItem])

    const updateClient = event => {
        setClient(event.target.value);
        setError('');
        setSuccess('');
    }

    const updateReference = event => {
        setReference(event.target.value);
        setError('');
        setSuccess('');
    }

    const updateBay = event => {
        setBay(event.target.value);
        setError('');
        setSuccess('');
    }

    const updateNotes = event => {
        setNotes(event.target.value);
        setError('');
        setSuccess('');
    }

    const updateDate = event => {
        setDate(event.target.value);
        console.log('date', event.target.value);
        setError('');
        setSuccess('');
    }

    const updateType = event => {
        setType(event.target.value);
        setError('');
        setSuccess('');
    }

    const updateRacq = event => {
        setRacq(event.target.value);
        setError('');
        setSuccess('');
    }

    const updateBarcode = event => {
        setBarcode(event.target.value)
        setError('');
        setSuccess('');
    }

    const updateDesign = event => {
        setDesign(event.target.value);
        setError('');
        setSuccess('');
    }

    const updateSize = event => {
        setSize(event.target.value);
        setError('');
        setSuccess('');
    }

    const updateOrderNo = event => {
        setOrderNo(event.target.value);
        setError('');
        setSuccess('');
    }

    const updateSR = event => {
        setSrNo(event.target.value);
        setError('');
        setSuccess('');
    }

    const clearInputs = () => {
        setClient('');
        setReference('');
        setBay('');
        setNotes('');
        setBarcode('');
        setDesign('');
        setSize('');
        setOrderNo('');
        setSrNo('');
    }

    const submit = (event) => {
        event.preventDefault();
        setLoading(true);

        let lib = '';
        if (pathname.includes('admin')) {
            lib = 'stock';
        }
        if (pathname.includes('dispatch')) {
            lib = 'dispatch';
        }

        if (client.trim() == "" || reference.trim() == "" ||
            type.trim() == "" || date.trim() == "") {
            setError('Please fill in Client, Reference, Type and Recieved');
            return;
        }

        let data1 = {
            title: client,
            status: 'publish',
            acf: {
                client: client,
                reference: reference,
                bay_number: bay,
                notes: notes,
                type: type,
                received: convertDateToNzFormat(date),
                racq_number: racq,
                barcode: barcode,
                design: design,
                size: size,
                order_number: orderNo,
                sr_number: srNo,
            }
        }

        let data2 = {
            title: client,
            status: 'publish',
            acf: {
                client: client,
                reference: reference,
                bay_number: bay,
                notes: notes,
                type: type,
                date: convertDateToNzFormat(date),
                racq_number: racq,
                barcode: barcode,
                design: design,
                size: size,
                order_number: orderNo,
                sr_number: srNo,
            }
        }

        let body = lib == 'stock' ? data1 : data2;

        saveItem(id, lib, body).then(res => {
            setSuccess('Item updated');
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setError('failed to update item');
        })
    }

    const customAlert = item => {
        setPrintItem(item);
    }

    const closeAlert = () => {
        setPrintItem(null);
        setVisible(false);
    }

    const startPrint = () => {
        if (printItem == null) return;

        if (to.trim() === '') {
            alert('Must enter a Delivery to');
            closeAlert();
            return;
        }

        let uri = encodeURIComponent(to);

        if (getBrowser() === 'Apple Safari') {
            openWindowInMac('/print/' + id + '/' + uri)
        } else {
            window.open('/print/' + id + '/' + uri, '_blank');
        }

        navigate('/dashboard/dispatch');
    }

    const saveAndPrint = (event) => {
        event.preventDefault();
        setLoading(true);

        let lib = '';
        if (pathname.includes('admin')) {
            lib = 'stock';
        }
        if (pathname.includes('dispatch')) {
            lib = 'dispatch';
        }

        if (client.trim() == "" || reference.trim() == "" ||
            type.trim() == "" || date.trim() == "") {
            setError('Please fill in Client, Reference, Type and Recieved');
            return;
        }

        let data1 = {
            title: client,
            status: 'publish',
            acf: {
                client: client,
                reference: reference,
                bay_number: bay,
                notes: notes,
                type: type,
                received: convertDateToNzFormat(date),
                racq_number: racq,
                barcode: barcode,
                design: design,
                size: size,
                order_number: orderNo,
            }
        }

        let data2 = {
            title: client,
            status: 'publish',
            acf: {
                client: client,
                reference: reference,
                bay_number: bay,
                notes: notes,
                type: type,
                date: convertDateToNzFormat(date),
                racq_number: racq,
                barcode: barcode,
                design: design,
                size: size,
                order_number: orderNo,
                invoice_number: invoiceNumber,
            }
        }

        let body = lib == 'stock' ? data1 : data2;

        saveItem(id, lib, body).then(res => {
            setSuccess('Item updated');
            setLoading(false);
            if (lib === 'stock') {
                if (getBrowser() === 'Apple Safari') {
                    openWindowInMac('/print2/' + id + '/' + barcode)
                } else {
                    window.open('/print2/' + id + '/' + barcode, '_blank');
                }
                navigate('/');
            } else {
                customAlert({ id });
            }

        }).catch(error => {
            console.error(error);
            setError('failed to update item');
        })
    }

    const deleteItem = (event) => {
        let proceed = window.confirm('Are you sure you wish to delete racq item: ' + racq + '?');

        if (!proceed) return;

        event.preventDefault();
        setLoading(true);

        if (pathname.includes('dispatch')) {
            deleteDispatch(id).then(res => {
                console.log('deleted res', res);
                setLoading(false)
                setSuccess('Item deleted');
                navigate('/dashboard/dispatch')
            }).catch(error => {
                console.error(error);
                setError('failed to delete item');
            })
        } else {
            deleteInventory(id).then(res => {
                console.log('deleted res', res);
                setLoading(false)
                setSuccess('Item deleted');
                navigate('/dashboard/admin')
            }).catch(error => {
                console.error(error);
                setError('failed to delete item');
            })
        }

    }

    const openWindowInMac = (url) => {
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.target = 'blank'
        a.click();
        document.body.removeChild(a);
    }

    return (
        <Container
            style={container}
        >
            <h2
                style={{
                    margin: '1%',
                    color: 'var(--main-color)'
                }}
            >
                Edit Item
            </h2>
            <hr

                style={{
                    margin: '1%',
                }}
            />

            <div style={formContainer}>
                <form autoComplete='off' style={form} onSubmit={submit} >
                    <Textbox
                        label="Racq #"
                        id="racq"
                        name="racq"
                        onChange={updateRacq}
                        value={racq}
                        txtstyle={inputStyle}
                    />
                    <Textbox
                        label="Client"
                        list="client-list"
                        id="client"
                        name="client"
                        onChange={updateClient}
                        value={client}
                        txtstyle={inputStyle}
                    />
                    <datalist id='client-list'>
                        {
                            clientList.map((item, index) => {
                                return (
                                    <option value={item} key={index} />
                                )
                            })
                        }
                    </datalist>

                    <Textbox
                        label="Reference"
                        id="reference"
                        value={reference}
                        onChange={updateReference}
                        txtstyle={inputStyle}
                    />
                    <Textbox
                        label="Barcode"
                        id="barcode"
                        value={barcode}
                        onChange={updateBarcode}
                        txtstyle={inputStyle}
                    />
                    <Textbox
                        label="Design / Colour"
                        id="design"
                        value={design}
                        onChange={updateDesign}
                        txtstyle={inputStyle}
                    />

                    <Textbox
                        label="Size"
                        id="size"
                        value={size}
                        onChange={updateSize}
                        txtstyle={inputStyle}
                    />

                    <Textbox
                        label="Order Number"
                        id="orderNo"
                        value={orderNo}
                        onChange={updateOrderNo}
                        txtstyle={inputStyle}
                    />
                    <Textbox
                        label="SR Number"
                        id="srNumber"
                        value={srNo}
                        onChange={updateSR}
                        txtstyle={inputStyle}
                    />
                    <Textbox
                        label="Bay"
                        id="bay"
                        value={bay}
                        onChange={updateBay}
                        txtstyle={inputStyle}
                    />
                    <Textbox
                        label="Notes"
                        id="notes"
                        value={notes}
                        onChange={updateNotes}
                        txtstyle={inputStyle}
                    />
                    <Textbox
                        label="Type"
                        list="type-list"
                        id="type"
                        name="type"
                        onChange={updateType}
                        value={type}
                        txtstyle={inputStyle}
                    />
                    <datalist id="type-list">
                        <option value="carpet" />
                        <option value="rug" />
                        <option value="pallet" />
                        <option value="entrance rug" />
                        <option value="sales / floor stock" />
                        <option value="other" />
                    </datalist>
                    {
                        pathname.includes('dispatch') ?
                            <Textbox
                                label="Invoice Number"
                                type="text"
                                value={invoiceNumber}
                                onChange={e => setInvoiceNumber(e.target.value)}
                                txtstyle={inputStyle}
                            />
                            : null
                    }
                    <Textbox
                        label={pathname.includes('dispatch') ? 'Dispatch Date' : 'Received'}
                        type="date"
                        value={date}
                        onChange={updateDate}
                        txtstyle={inputStyle}
                    />

                    <div style={btnContainer}>
                        <Button
                            onClick={submit}
                            width="90%"
                            btnstyle={btn}
                        >
                            Save
                        </Button>
                        <Button
                            onClick={saveAndPrint}
                            width="90%"
                            btnstyle={btn}
                        >
                            Print & Save
                        </Button>
                        <Button
                            onClick={deleteItem}
                            width="90%"
                            btnstyle={btn}
                        >
                            Delete
                        </Button>
                    </div>

                </form>
                {
                    error == '' ? null :
                        <div
                            style={errorStyle}
                        >
                            {error}
                        </div>
                }
                {
                    success == '' ? null :
                        <div
                            style={successStyle}
                        >
                            {success}
                        </div>
                }
                {
                    loading ? <div style={loader}><BarLoader width="100%" color="#0571cb" loading={true} /></div> : null
                }
            </div>

            <Modal
                visible={visible}
                close={closeAlert}
            >
                <div>
                    <h3>Confirm Print Details</h3>

                    <Textbox
                        label="Deliver To"
                        id="to"
                        value={to}
                        onChange={event => setTo(event.target.value)}
                    />


                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '0.5rem'
                    }}
                >
                    <Button
                        btnstyle={{ marginRight: '0.5rem' }}
                        onClick={startPrint}
                    >
                        Preview
                    </Button>
                    <Button onClick={closeAlert}>
                        Cancel
                    </Button>
                </div>
            </Modal>

        </Container>
    );
}

const container = {
    flexDirection: 'column',
    width: '90%',
    alignSelf: 'center',
    padding: '1%',
    height: '100%',
    overflow: 'hidden',
    boxSizing: 'border-box',
    overflow: 'auto'
}

const formContainer = {

}

const form = {

}

const errorStyle = {
    border: 'var(--main-border)',
    margin: '2% 0px',
    width: 'fit-content',
    padding: '2%',
    backgroundColor: '#ff00003b',
    color: 'red',
    fontFamily: 'var(--secondary-font)',
    fontSize: '0.8rem',

}

const successStyle = {
    border: 'var(--main-border)',
    margin: '2% 0px',
    width: 'fit-content',
    padding: '2%',
    backgroundColor: '#00ff003b',
    color: 'green',
    fontFamily: 'var(--secondary-font)',
    fontSize: '0.8rem',

}

const inputStyle = {
    width: '90%',
    maxWidth: '400px',
    paddingBottom: '0.75rem'
}

const btn = {
    maxWidth: '200px',
    margin: '0.3rem'
}

const loader = {
    display: 'flex',
    marginTop: '10px',
    maxWidth: '330px',
    width: '100%',
}

const btnContainer = {
    display: 'flex',
    flexDirection: 'row'
}

export default Edit;