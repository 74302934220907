import React, { useEffect, useState } from 'react';

import Container from '../visual/Container';
import Textbox from '../visual/Textbox';
import Button from '../visual/Button';
import moment from 'moment';

import { useNavigate, useParams } from 'react-router-dom';

import {
    getClientList,
    convertDateToNzFormat,
    getBrowser,
    openWindowInMac,
    getItem
} from '../util/Functions';
import axios from 'axios';

import BarLoader from 'react-spinners/BarLoader';


const Add = () => {

    const [clientList, setClientList] = useState(['Award', 'Nodi', 'Sallee', 'SF Design', 'Luxe']);
    const [client, setClient] = useState('');
    const [reference, setReference] = useState('');
    const [bay, setBay] = useState('');
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
    const [notes, setNotes] = useState('');
    const [type, setType] = useState('');
    const [barcode, setBarcode] = useState('');
    const [design, setDesign] = useState('');
    const [size, setSize] = useState('');
    const [orderNo, setOrderNo] = useState('');
    const [srNo, setSrNo] = useState('');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const [id, setId] = useState(null);

    const [disablePrint, setDisablePrint] = useState(true);


    let navigate = useNavigate();
    let { copyId } = useParams();

    useEffect(() => {
        getClientList('/wp-json/wp/v2/stock', 'keys').then(data => {
            let newList = [...clientList];
            for (let c of data) {
                if (!newList.includes(c)) {
                    newList.push(c);
                }
            }
            setClientList(newList);
        });

        if (copyId) {
            getItem(copyId, 'dispatch').then(res => {
                let data = res.data.acf;

                setClient(prev => data.client ? data.client : prev);
                setReference(prev => data.reference ? data.reference : prev);
                setDesign(prev => data.design ? data.design : prev);
                setSize(prev => data.size ? data.size : prev);
                setOrderNo(prev => data.order_number ? data.order_number : prev);
                setSrNo(prev => data.sr_number ? data.sr_number : prev);
                setBay(prev => data.bay_number ? data.bay_number : prev);
                setNotes(prev => data.notes ? data.notes : prev);
                setType(prev => data.type ? data.type : prev);

            }).catch(error => {
                alert('Network Error:: getItem');
                console.error(error);
            })
        }


    }, []);

    useEffect(() => {
        if (id) {
            setDisablePrint(false);
        }
    }, [id])


    const submit = (event) => {
        event.preventDefault();
        setLoading(true);

        const url = '/wp-json/wp/v2/stock';
        const token = localStorage.getItem('raqd_token');

        if (client.trim() === "" || reference.trim() === "" ||
            type.trim() === "" || date.trim() === "") {
            setError('Please fill in Client, Reference, Type and Recieved');
            setLoading(false);
            return;
        }


        let body = {
            title: client,
            status: 'publish',
            acf: {
                client: client,
                reference: reference,
                bay_number: bay,
                notes: notes,
                type: type,
                received: convertDateToNzFormat(date),
                ...(barcode.trim() !== '') && { barcode: barcode },
                design: design,
                size: size,
                order_number: orderNo,
                sr_number: srNo,
            }
        }


        let header = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }


        axios.post(url, body, header).then(res => {
            console.log('added to inventory: ', res.data);
            setSuccess('new item added to inventory');
            setLoading(false);
            setId(res.data.id);
            setBarcode(res.data.acf.barcode);
        }).catch(error => {
            const err = error;
            if (err.res) {
                console.log(err.res.status)
                console.log(err.res.data)
                setError(err.res.data.message);
            } else {
                console.error(err);
                setError('Failed to add item to inventory');
            }
        })


    }

    const updateClient = event => {
        setClient(event.target.value);
        setError('');
        setSuccess('');
    }

    const updateReference = event => {
        setReference(event.target.value);
        setError('');
        setSuccess('');
    }

    const updateBay = event => {
        setBay(event.target.value);
        setError('');
        setSuccess('');
    }

    const updateNotes = event => {
        setNotes(event.target.value);
        setError('');
        setSuccess('');
    }

    const updateDate = event => {
        setDate(event.target.value);
        console.log('date', event.target.value);
        setError('');
        setSuccess('');
    }

    const updateType = event => {
        setType(event.target.value);
        setError('');
        setSuccess('');
    }

    const updateBarcode = event => {
        setBarcode(event.target.value);
        setError('');
        setSuccess('');
    }

    const updateDesign = event => {
        setDesign(event.target.value);
        setError('');
        setSuccess('');
    }

    const updateSize = event => {
        setSize(event.target.value);
        setError('');
        setSuccess('');
    }

    const updateOrderNo = event => {
        setOrderNo(event.target.value);
        setError('');
        setSuccess('');
    }

    const updateSR = event => {
        setSrNo(event.target.value);
        setError('');
        setSuccess('');
    }

    const clearInputs = () => {
        setClient('');
        setReference('');
        setBay('');
        setNotes('');
        setBarcode('');
        setDesign('');
        setSize('');
        setOrderNo('');
        setSrNo('')
    }

    const print = () => {

        setLoading(true);

        const url = '/wp-json/wp/v2/stock';
        const token = localStorage.getItem('raqd_token');

        if (client.trim() === "" || reference.trim() === "" ||
            type.trim() === "" || date.trim() === "") {
            setError('Please fill in Client, Reference, Type and Recieved');
            setLoading(false);
            return;
        }


        let body = {
            title: client,
            status: 'publish',
            acf: {
                client: client,
                reference: reference,
                bay_number: bay,
                notes: notes,
                type: type,
                received: convertDateToNzFormat(date),
                ...(barcode.trim() !== '') && { barcode: barcode },
                design: design,
                size: size,
                order_number: orderNo,
                sr_number: srNo,
            }
        }


        let header = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }


        axios.post(url, body, header).then(res => {
            console.log('added to inventory: ', res.data);
            setSuccess('new item added to inventory');
            setLoading(false);
            setId(res.data.id);

            setBarcode(res.data.acf.barcode);
            if (getBrowser() === 'Apple Safari') {
                openWindowInMac('/print2/' + res.data.id + '/' + res.data.acf.barcode)
            } else {
                window.open('/print2/' + res.data.id + '/' + res.data.acf.barcode, '_blank');
            }

            navigate('/');
        }).catch(error => {
            const err = error;
            if (err.res) {
                console.log(err.res.status)
                console.log(err.res.data)
                setError(err.res.data.message);
            } else {
                console.error(err);
                setError('Failed to add item to inventory');
            }
        })


    }

    return (
        <Container
            style={container}
        >
            <h2
                style={{
                    margin: '1%',
                    color: 'var(--main-color)'
                }}
            >
                Add New Entry to Inventory
            </h2>
            <hr

                style={{
                    margin: '1%',
                }}
            />

            <div style={formContainer}>
                <form autoComplete='off' style={form} onSubmit={submit} >
                    <Textbox
                        label="Client"
                        list="client-list"
                        id="client"
                        name="client"
                        onChange={updateClient}
                        value={client}
                        txtstyle={inputStyle}
                    />
                    <datalist id='client-list'>
                        {
                            clientList.map((item, index) => {
                                return (
                                    <option value={item} key={index} />
                                )
                            })
                        }
                    </datalist>

                    <Textbox
                        label="Reference"
                        id="reference"
                        value={reference}
                        onChange={updateReference}
                        txtstyle={inputStyle}
                    />
                    <Textbox
                        label="Barcode"
                        id="barcode"
                        value={barcode}
                        onChange={updateBarcode}
                        txtstyle={inputStyle}
                    />

                    <Textbox
                        label="Design / Colour"
                        id="design"
                        value={design}
                        onChange={updateDesign}
                        txtstyle={inputStyle}
                    />

                    <Textbox
                        label="Size"
                        id="size"
                        value={size}
                        onChange={updateSize}
                        txtstyle={inputStyle}
                    />

                    <Textbox
                        label="Order Number"
                        id="orderNo"
                        value={orderNo}
                        onChange={updateOrderNo}
                        txtstyle={inputStyle}
                    />

                    <Textbox
                        label="SR Number"
                        id="srNumber"
                        value={srNo}
                        onChange={updateSR}
                        txtstyle={inputStyle}
                    />

                    <Textbox
                        label="Bay"
                        id="bay"
                        value={bay}
                        onChange={updateBay}
                        txtstyle={inputStyle}
                    />
                    <Textbox
                        label="Notes"
                        id="notes"
                        value={notes}
                        onChange={updateNotes}
                        txtstyle={inputStyle}
                    />
                    <Textbox
                        label="Type"
                        list="type-list"
                        id="type"
                        name="type"
                        onChange={updateType}
                        value={type}
                        txtstyle={inputStyle}
                    />
                    <datalist id="type-list">
                        <option value="carpet" />
                        <option value="rug" />
                        <option value="pallet" />
                        <option value="entrance rug" />
                        <option value="sales / floor stock" />
                        <option value="other" />
                    </datalist>
                    <Textbox
                        label="Recieved"
                        type="date"
                        value={date}
                        onChange={updateDate}
                        txtstyle={inputStyle}
                    />

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Button
                            onClick={submit}
                            width="90%"
                            btnstyle={btn}
                        >
                            Add
                        </Button>
                        <Button
                            onClick={print}
                            width="90%"
                            btnstyle={btn}
                        >
                            Save and Print
                        </Button>
                    </div>

                </form>
                {
                    error === '' ? null :
                        <div
                            style={errorStyle}
                        >
                            {error}
                        </div>
                }
                {
                    success === '' ? null :
                        <div
                            style={successStyle}
                        >
                            {success}
                        </div>
                }
                {
                    loading ? <div style={loader}><BarLoader width="100%" color="#0571cb" loading={true} /></div> : null
                }
            </div>

        </Container>
    );
}

const container = {
    flexDirection: 'column',
    width: '90%',
    alignSelf: 'center',
    padding: '1%',
    height: '100%',
    overflow: 'auto',
    boxSizing: 'border-box'
}

const formContainer = {

}

const form = {

}

const errorStyle = {
    border: 'var(--main-border)',
    margin: '2% 0px',
    width: 'fit-content',
    padding: '2%',
    backgroundColor: '#ff00003b',
    color: 'red',
    fontFamily: 'var(--secondary-font)',
    fontSize: '0.8rem',

}

const successStyle = {
    border: 'var(--main-border)',
    margin: '2% 0px',
    width: 'fit-content',
    padding: '2%',
    backgroundColor: '#00ff003b',
    color: 'green',
    fontFamily: 'var(--secondary-font)',
    fontSize: '0.8rem',

}

const inputStyle = {
    width: '90%',
    maxWidth: '400px',
    paddingBottom: '0.75rem'
}

const btn = {
    maxWidth: '300px',
    margin: '0.5rem'
}

const loader = {
    display: 'flex',
    marginTop: '10px',
    maxWidth: '330px',
    width: '100%',
}

export default Add;