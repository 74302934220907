import React, { useState } from 'react';

const Checkbox = (props) => {

    const [checked, setChecked] = useState(false);

    const onHandleClick = e => {
        props.onChange(e);
    }

    const handlePropagation = e => {
        //console.log('event', e.target);
        //  setChecked(prev => !prev);

        e.stopPropagation();
        return false;
    }

    return (
        <div data-key={props.data} onClick={onHandleClick} style={{ ...props.container, ...container }}>
            <input value={!!props.checked} checked={props.checked} onChange={props.onChange} name={props.name} type="checkbox" style={{ ...props.style, ...box }} />
            <label style={{ ...props.lblstyle, ...lbl }} htmlFor={props.name}>{props.label}</label>
        </div>
    );
}

const container = {
    padding: '5px 0',
    display: 'flex',
    position: 'relative'
}

const box = {
    marginRight: '5px',
    PointerEvents: 'none'
}

const lbl = {
    fontSize: '0.75rem',
    position: 'absolute',
    paddingLeft: '1.1rem'
}

export default Checkbox;