import axios from "axios";
import moment from "moment";

export async function getClientList(url = '/wp-json/wp/v2/stock', option = null) {

    let number_of_pages = 1;
    let current_page = 1;
    let list = {};

    if (url == 'clients') {
        url = '/wp-json/wp/v2/stock'
    }

    while (current_page <= number_of_pages) {
        let res = await axios.get(url, {
            params: {
                per_page: 100,
                page: current_page,

            }
        })

        if (res.status === 200) {
            for (let item of res.data) {

                let newItem = { id: item.id, ...item.acf }

                if (!list[item.acf.client]) {
                    let array = [newItem];
                    list[item.acf.client] = { data: array };
                } else {
                    list[item.acf.client].data.push(newItem);
                }

            }
            number_of_pages = res.headers['x-wp-totalpages'];
        }

        current_page += 1;

    }

    if (option == 'keys') {
        return Object.keys(list);
    }



    console.log('items::', list);
    return list;

}

export async function getData(url, option = null) {
    let number_of_pages = 1;
    let current_page = 1;
    let list = [];


    while (current_page <= number_of_pages) {
        let res = await axios.get(url, {
            params: {
                per_page: 100,
                page: current_page,

            }
        })

        if (res.status === 200) {

            for (let data of res.data) {

                let item = {
                    id: data.id,
                    ...data.acf,
                }

                list.push(item);

            }

            number_of_pages = res.headers['x-wp-totalpages'];

        } else {
            return res;
        }

        current_page += 1;
    }

    return list;

}

export async function getDispatchList(option = null) {
    const url = '/wp-json/wp/v2/dispatch';

    let number_of_pages = 1;
    let current_page = 1;
    let list = {};

    while (current_page <= number_of_pages) {
        let res = await axios.get(url, {
            params: {
                per_page: 100,
                page: current_page,

            }
        })

        if (res.status === 200) {
            for (let item of res.data) {

                let newItem = { id: item.id, ...item.acf }

                if (!list[item.acf.client]) {
                    let array = [newItem];
                    list[item.acf.client] = { data: array };
                } else {
                    list[item.acf.client].data.push(newItem);
                }

            }
            number_of_pages = res.headers['x-wp-totalpages'];
        }

        current_page += 1;

    }

    if (option === 'keys') {
        return Object.keys(list);
    }

    console.log('items::', list);
    return list;

}

export async function deleteInventory(id) {
    const url = '/wp-json/wp/v2/stock/' + id;
    const token = localStorage.getItem('raqd_token');

    let header = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }

    return await axios.delete(url, header);

}

export async function deleteDispatch(id) {
    const url = '/wp-json/wp/v2/dispatch/' + id;
    const token = localStorage.getItem('raqd_token');

    let header = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }

    return await axios.delete(url, header);

}

export async function addDispatchItems(item, date, invoiceNumber) {
    const url = '/wp-json/wp/v2/dispatch';
    const token = localStorage.getItem('raqd_token');

    let header = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }

    let body = {
        title: item.client,
        status: 'publish',
        acf: {
            client: item.client,
            reference: item.reference,
            bay_number: item.bay_number,
            notes: item.notes,
            type: item.type,
            date: date,
            racq_number: item.racq_number,
            barcode: item.barcode,
            design: item.design,
            size: item.size,
            order_number: item.order_number,
            sr_number: item.sr_number,
            invoice_number: invoiceNumber,
        }
    }

    return await axios.post(url, body, header);

}

export async function addInventoryItems(item) {
    const url = '/wp-json/wp/v2/stock';
    const token = localStorage.getItem('raqd_token');

    let header = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }

    let body = {
        title: item.client,
        status: 'publish',
        acf: {
            client: item.client,
            reference: item.reference,
            bay_number: item.bay_number,
            notes: item.notes,
            type: item.type,
            received: item.received ? item.received : moment().format('D/M/YYYY'),
            racq_number: item.racq_number,
            barcode: item.barcode,
            design: item.design,
            size: item.size,
            order_number: item.order_number,
            sr_number: item.sr_number
        }
    }

    return await axios.post(url, body, header);
}

export async function getItem(id, lib) {
    const url = '/wp-json/wp/v2/' + lib + '/' + id;

    return await axios.get(url)

}

export async function saveItem(id, lib, body) {
    const url = '/wp-json/wp/v2/' + lib + '/' + id;
    const token = localStorage.getItem('raqd_token');

    let header = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }

    return await axios.post(url, body, header);
}

export function convertDateToNzFormat(stringDate) {
    if (stringDate.trim() === '') return '';
    let oldDate = moment(stringDate, 'YYYY-MM-DD');
    let newDate = oldDate.format('DD/MM/YYYY');
    return newDate;
}

export function convertNzDateToReadable(nzDateString) {
    if (nzDateString.trim() === '') return '';
    let oldDate = moment(nzDateString, 'DD/MM/YYYY');
    let newDate = oldDate.format('YYYY-MM-DD');
    return newDate
}

export async function archiveItem(id) {
    const url = '/wp-json/wp/v2/dispatch' + '/' + id;
    const token = localStorage.getItem('raqd_token');

    let header = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }

    let body = {
        acf: {
            archive: true
        }
    }

    return await axios.post(url, body, header);
}

export function getBrowser() {

    var sBrowser, sUsrAg = navigator.userAgent;

    // The order matters here, and this may report false positives for unlisted browsers.

    if (sUsrAg.indexOf("Firefox") > -1) {
        sBrowser = "Mozilla Firefox";
        // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
        sBrowser = "Samsung Internet";
        // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
    } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
        sBrowser = "Opera";
        // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
    } else if (sUsrAg.indexOf("Trident") > -1) {
        sBrowser = "Microsoft Internet Explorer";
        // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (sUsrAg.indexOf("Edge") > -1) {
        sBrowser = "Microsoft Edge";
        // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (sUsrAg.indexOf("Chrome") > -1) {
        sBrowser = "Google Chrome or Chromium";
        // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (sUsrAg.indexOf("Safari") > -1) {
        sBrowser = "Apple Safari";
        // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    } else {
        sBrowser = "unknown";
    }

    console.log('Browser:', sBrowser);
    return sBrowser;

}

export function openWindowInMac(url) {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.target = 'blank'
    a.click();
    document.body.removeChild(a);
}

export async function getDispatchListBySearch(search) {
    let url = '/wp-json/search/v1/invoice';

    let res = await axios.get(url, {
        params: {
            invoice: search,
        }
    })

    return res;

}