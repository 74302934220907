import React from 'react';

import Radium from 'radium';

const Card = (props) => {

    const styles = {
        backgroundColor: 'var(--card-surface)',
        boxShadow: 'var(--box-shadow)',
        width: props.width ? props.width : 'auto',
        height: props.height ? props.height : 'auto',
        padding: '2%',
    }

    return (
        <div {...props} onClick={props.onClick} style={{ ...styles, ...props.style }}>
            {props.children}
        </div>
    );
}

export default Radium(Card);