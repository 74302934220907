import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { getData } from '../util/Functions';
import useSWR, { useSWRConfig } from 'swr';

import Container from '../visual/Container';
import UserStockList from '../components/UserStockList';
import Textbox from '../visual/Textbox';
import Select from '../visual/Select';

import BeatLoader from 'react-spinners/BeatLoader';

const UserDispatch = () => {

    const navigate = useNavigate();

    const { data: stock, error: stockError, isValidating } = useSWR('/wp-json/wp/v2/dispatch', (url) => getData(url).then(r => r));
    const { mutate } = useSWRConfig()

    const [orderedItems, setOrderedItems] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState('');
    const [searchBy, setSearchBy] = useState('All');
    const [search, setSearch] = useState('');

    const orderby = [
        'Racq #',
        'Reference',
        'Bay',
        'Design',
        'Order #',
        'Recieved',
        'SR #',
        'Type'
    ]

    useEffect(() => {
        if (localStorage.getItem('raqd_role') == 'administrator') {
            navigate('/dashboard/admin')
        }

    });

    useEffect(() => {

        reOrder();

    }, [selectedOrder]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            console.log(search)
            reOrder();
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [search])

    useEffect(() => {
        if (stock) {
            reOrder();
        }
        if (stockError) {
            console.log('stockError', stockError);
            alert('Network Error:: Please refresh or contact administrator');
        }
    }, [stock])


    const reOrder = () => {


        let filterData = {
            search: search,
            list: stock,
            searchBy: searchBy,
            selectedOrder: selectedOrder,
        }



        filter(filterData).then(data => {
            setOrderedItems(data);
        }).catch(error => {
            console.log(error);
        })

        return;

    }

    const filter = async (data) => {

        var search = data.search;
        var list = data.list;
        var searchBy = data.searchBy;
        var selectedOrder = data.selectedOrder;
        var selectedClient = localStorage.getItem('raqd_company');

        let promise = new Promise((resolve, reject) => {
            if (!list) {
                reject('list not found');
            }

            //Apply Client filter
            list = list.filter(x => x.client.toLowerCase() === selectedClient.toLowerCase());

            //remove all archived
            list = list.filter(x => !x.archive);

            //Apply orderby
            list.sort((a, b) => {
                switch (selectedOrder) {
                    case '':
                        if (a.racq_number < b.racq_number) return -1;
                        if (a.racq_number > b.racq_number) return 1;
                        return 0;
                    case 'Racq #':
                        if (a.racq_number < b.racq_number) return -1;
                        if (a.racq_number > b.racq_number) return 1;
                        return 0;
                    case 'Reference':
                        if (a.reference < b.reference) return -1;
                        if (a.reference > b.reference) return 1;
                        return 0;
                    case 'Bay':
                        if (a.bay_number < b.bay_number) return -1;
                        if (a.bay_number > b.bay_number) return 1;
                        return 0;
                    case 'Recieved':
                        if (a.received < b.received) return -1;
                        if (a.received > b.received) return 1;
                        return 0;
                    case 'Order #':
                        if (a.order_number < b.order_number) return -1;
                        if (a.order_number > b.order_number) return 1;
                        return 0;
                    case 'SR #':
                        if (a.sr_number < b.sr_number) return -1;
                        if (a.sr_number > b.sr_number) return 1;
                        return 0;
                    case 'Type':
                        if (a.type < b.type) return -1;
                        if (a.type > b.type) return 1;
                        return 0;
                }
            })


            switch (searchBy.trim()) {
                case '':
                    break;
                case 'Racq #':
                    list = list.filter(x => x.racq_number.toLowerCase().includes(search.toLowerCase()))
                    break;
                case 'Reference':
                    list = list.filter(x => x.reference.toLowerCase().includes(search.toLowerCase()) ||
                        x.barcode.toLowerCase().includes(search.toLowerCase()))
                    break;
                case 'Bay':
                    list = list.filter(x => x.bay_number.toLowerCase().includes(search.toLowerCase()))
                    break;
                case 'Recieved':
                    list = list.filter(x => x.received.toLowerCase().includes(search.toLowerCase()))
                    break;
                case 'Order #':
                    list = list.filter(x => x.order_number.toLowerCase().includes(search.toLowerCase()))
                    break;
                case 'Design':
                    list = list.filter(x => x.design.toLowerCase().includes(search.toLowerCase()))
                    break;
                case 'SR #':
                    list = list.filter(x => x.sr_number.toLowerCase().includes(search.toLowerCase()))
                    break;
                case 'Type':
                    list = list.filter(x => x.type.toLowerCase().includes(search.toLowerCase()))
                    break;
            }

            //Search by all 
            if (searchBy.trim() === 'All') {
                list = list.filter(x => {
                    if (x.racq_number?.toLowerCase()?.includes(search.toLowerCase()) ||
                        x.reference?.toLowerCase()?.includes(search.toLowerCase()) ||
                        x.barcode?.toLowerCase()?.includes(search.toLowerCase()) ||
                        x.bay_number?.toLowerCase()?.includes(search.toLowerCase()) ||
                        x.received?.toLowerCase()?.includes(search.toLowerCase()) ||
                        x.order_number?.toLowerCase()?.includes(search.toLowerCase()) ||
                        x.sr_number?.toLowerCase()?.includes(search.toLowerCase()) ||
                        x.design?.toLowerCase()?.includes(search.toLowerCase()) ||
                        x.type?.toLowerCase()?.includes(search.toLowerCase())) {
                        return true
                    } else {
                        return false;
                    }
                })

            }

            resolve(list);
        })

        return promise;

    }

    return (
        <Container style={container}>
            <h2 style={h2}>Picked and Ready</h2>
            <hr style={hr} />
            <div style={topFilters}>
                <Select
                    label="Order by"
                    list={orderby}
                    id="orderby"
                    name="orderby"
                    value={selectedOrder}
                    onChange={event => setSelectedOrder(event.target.value)}
                    containerstyle={filterInput}
                />
                <Select
                    label="Search by"
                    list={['All', ...orderby]}
                    id="search-by"
                    value={searchBy}
                    onChange={event => setSearchBy(event.target.value)}
                    containerstyle={filterInput}
                />
            </div>
            <Textbox
                type="search"
                placeholder="search"
                txtstyle={searchbox}
                onChange={e => setSearch(e.target.value)}
                value={search}
            />
            <div style={{ marginTop: '3px', minHeight: '35px' }}>
                <p style={show}>Showing {orderedItems ? orderedItems.length : 0} {orderedItems && orderedItems.length == 1 ? 'item' : 'items'}</p>
                <BeatLoader size={10} color="#0571cb" loading={isValidating} />
            </div>

            <UserStockList
                list={orderedItems}
            />
        </Container>
    );
}

const container = {
    padding: '2%',
    flexDirection: 'column',
    overflow: 'hidden'
}

const h2 = {
    color: 'var(--main-color)'
}

const hr = {
    marginTop: '1%',
    marginBottom: '1%',
}

const searchbox = {
    maxWidth: '90%'
}

const filterInput = {
    paddingRight: '0.3rem',
    width: '50%'
}

const topFilters = {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '90%'
}

const show = {
    fontWeight: 'bold',
    fontSize: '0.85rem'
}

export default UserDispatch;