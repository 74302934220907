import React, { useState } from 'react';

import Card from '../visual/Card';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';


const ListItem = ({ data }) => {

    const [collapsed, setCollapsed] = useState(true);


    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    }

    return (
        <Card style={container} onClick={toggleCollapsed} className={collapsed ? 'collapsed' : 'expanded'}>
            <h3 style={h3}>Reference: {data.reference}</h3>
            {
                !collapsed && Object.keys(data).map((key, index) => {

                    //filter unwanted fields
                    if (key === 'id' || key === 'client') return null;

                    return (
                        <div style={itemRow} key={index}>
                            <p style={title}>{key}:</p>
                            <p>{data[key]}</p>
                        </div>
                    )
                })
            }

            {
                collapsed && Object.keys(data).map((key, index) => {

                    //filter unwanted fields
                    if (key === 'racq_number' || key === 'type' || key == 'design') {
                        return (
                            <div style={itemRow} key={index}>
                                <p style={title}>{key}:</p>
                                <p>{data[key]}</p>
                            </div>
                        )
                    } else {
                        return null
                    }


                })
            }

            <div style={icons} >
                {
                    collapsed ? <AiOutlinePlus /> : <AiOutlineMinus />
                }
            </div>

        </Card>
    );
}


const container = {
    width: '90%',
    borderRadius: '10px',
    fontFamily: 'var(--secondary-font)',
    transition: 'max-height 1s ease',
    cursor: 'pointer'
}

const title = {
    fontWeight: '600',
    fontSize: '0.9rem',
    color: 'var(--main-text-color)',
    marginRight: '0.3rem'
}

const itemRow = {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '0.9rem'
}

const h3 = {
    color: 'var(--main-color)'
}

const icons = {
    padding: '3px',
    display: 'flex',
    justifyContent: 'flex-end',
    color: 'var(--main-color)'
}



export default ListItem;