import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';


import ArialBold from '../assets/fonts/arialbd.ttf';
import ArialBlack from '../assets/fonts/ariblk.ttf'

import Logo from '../images/RACQ_Black_Square2.png'

const NewDocket = ({ srNo, racq, client, design, reference, source, size, orderNo }) => {

    // Font.register({
    //     family: 'OpenSans', fonts: [
    //         { src: Open400Sans, fontWeight: 'normal' },
    //         { src: Open500Sans, fontWeight: 'bold' },
    //         { src: Open800Sans, fontWeight: 'ultrabold' },

    //     ]

    // })

    Font.register({
        family: 'Arial', fonts: [
            { src: ArialBold, fontWeight: 'normal' },
            { src: ArialBlack, fontWeight: 'bold' },

        ]

    })


    // Create styles
    const styles = StyleSheet.create({
        page: {
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
            // alignItems: 'center',
            height: '100%',
            padding: '5'
        },
        container: {
            width: '272',
            height: '272',
            border: '2px solid black',
        },
        heading: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white',
            borderBottom: '2px solid black',
            paddingBottom: 5,
        },
        h1: {
            fontSize: 65,
            fontFamily: 'Arial',
            fontWeight: 'bold',
        },
        p: {
            padding: '5',
            display: 'flex',
            flexWrap: 'wrap',
            fontFamily: 'Arial',
            fontWeight: 'normal',
            fontSize: 12,
        },
        flow: {
            display: 'flex',
            flexDirection: 'column',
        },
        info: {
            borderBottom: '2px solid black',
            maxHeight: '120',
        },
        square: {
            width: '85',
            height: '100%',
            // borderLeft: '2px solid black',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
        },

        bottom: {
            display: 'flex',
            flexDirection: 'row',
            maxHeight: '100',
        },
        r: {
            fontSize: 40,
            fontFamily: 'Arial',
            fontWeight: 'bold',
            display: 'flex',
            flex: '1',
            textAlign: 'center',
            borderRight: '2px solid black',
            borderBottom: '2px solid black',
        },
        a: {
            fontSize: 40,
            fontFamily: 'Arial',
            fontWeight: 'bold',
            display: 'flex',
            flex: '1',
            textAlign: 'center',
            borderBottom: '2px solid black',
        },
        c: {
            fontSize: 40,
            fontFamily: 'Arial',
            fontWeight: 'bold',
            display: 'flex',
            flex: '1',
            textAlign: 'center',
        },
        q: {
            fontSize: 40,
            fontFamily: 'Arial',
            fontWeight: 'bold',
            display: 'flex',
            flex: '1',
            textAlign: 'center',
            borderLeft: '2px solid black',
            // borderTop: '2px solid black',
        },
        logo: {
            objectFit: 'contain',
            width: '100%',
            height: '100%',
        },
        barcodebox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: '1'
        }
    });

    return (
        <Document title={racq + ' label'} >
            <Page size={[283.465, 283.465]} style={styles.page}>

                <View style={styles.container}>
                    <View style={styles.heading}>
                        {racq ? <Text style={styles.h1}>
                            {racq}
                        </Text> : null}
                    </View>
                    <View style={styles.flow}>

                        <View style={styles.info}>
                            <Text style={styles.p} >
                                Client: {client}
                            </Text>
                            <Text style={styles.p} >
                                Length: {size}
                            </Text>
                            <Text style={styles.p}>
                                Ref: {reference}
                            </Text>
                            <Text style={styles.p}>
                                Order #: {orderNo}
                            </Text>
                            <Text style={styles.p}>
                                SR #: {srNo}
                            </Text>
                        </View>

                        <View style={styles.bottom} >
                            <View style={styles.barcodebox}>
                            {source ? <Image style={{ width: '150' }} src={source} /> : null}
                            </View>


                            <View style={styles.square} >
                                <Image src={Logo} style={styles.logo} />
                            </View>
                        </View>

                    </View>


                </View>
            </Page>
        </Document>
    );
}

export default NewDocket;