import React, { useState, useEffect } from 'react';
import { Auth } from '../State';
import { useRecoilValue } from 'recoil';
import { useNavigate, Outlet } from 'react-router-dom';

import Container from '../visual/Container';

import Navbar from '../components/Navbar';

const Dashboard = () => {

    const auth = useRecoilValue(Auth);

    let navigate = useNavigate();

    useEffect(() => {
        if (!auth) {
            //redirect to login
            navigate('/login');
        }
    }, [auth])

    return (
        <Container style={container}>
            <Navbar />
            <Outlet />
        </Container>
    );
}

const container = {
    flexDirection: 'column'
}

export default Dashboard;