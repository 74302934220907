import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import axios from 'axios';

axios.defaults.baseURL = 'https://racq.co.nz/';

//axios.defaults.baseURL = 'https://test.iddesign.co.nz/';

// axios.defaults.baseURL = 'http://localhost:8080/racq/';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


