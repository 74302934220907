import React, { useState } from 'react';
import { FiMenu } from 'react-icons/fi';

import Sidebar from './Sidebar';
import '../css/Navbar.css'

import Logo from '../images/RACQ_Black_Horizontal.png';

const Navbar = () => {
    const [menu, setMenu] = useState(false);

    const toggleMenu = () => {
        setMenu(prev => !prev);
    }

    return (
        <>
            <Sidebar
                visible={menu}
                onClose={toggleMenu}
            />
            <div
                style={container}
            >
                <FiMenu
                    style={{
                        fontSize: '3rem',
                        margin: '1rem'
                    }}
                    onClick={toggleMenu}
                />

                <img
                    alt="RACQ"
                    src={Logo}
                    style={{
                        marginLeft: 'auto',
                        padding: '.75rem',
                        marginRight: '.75rem',
                    }}

                    className="nav-logo"
                />

            </div>
        </>
    );

}

const container = {
    backgroundColor: 'var(--alt-bg-color)',
    display: 'flex',
    width: '100%',
    height: '75px',
    alignItems: 'center',
    borderBottom: 'var(--main-border)',
    color: 'var(--main-text-color)',
    position: 'relative'
}

export default Navbar;