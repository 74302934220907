import React, { useEffect, useState } from 'react';
import { useNavigate, } from 'react-router-dom';
import { Link } from 'react-router-dom'
import '../css/Admin.css';
import '../css/component.css';

import Container from '../visual/Container';
import Select from '../visual/Select';
import Textbox from '../visual/Textbox';
import Button from '../visual/Button';
import Checkbox from '../visual/Checkbox';

import {
    getClientList,
    deleteInventory,
    addDispatchItems,
    getData,
    convertDateToNzFormat,
    openWindowInMac,
    getBrowser,
    getDispatchListBySearch
} from '../util/Functions';
import Modal from '../visual/Modal';
import moment from 'moment';

import BarLoader from 'react-spinners/BarLoader';
import BeatLoader from 'react-spinners/BeatLoader';

import useSWR, { useSWRConfig } from 'swr'
import { CSVLink } from 'react-csv';

//reverted comit 1.09.2022

const Admin = () => {

    //const [stockItems, setStockItems] = useState({});
    const [orderedItems, setOrderedItems] = useState([]);
    const [clientList, setClientList] = useState(['All']);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const [selectedClient, setSelectedClient] = useState('');
    const [selectedOrder, setSelectedOrder] = useState('');
    const [searchBy, setSearchBy] = useState('All');
    const [search, setSearch] = useState('');

    const [visible, setVisible] = useState(false);
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [dispatchItem, setDispatchItem] = useState(null);
    const [dispatchLoader, setDispatchLoader] = useState(false);
    const [checkDispatch, setCheckDispatch] = useState(false);
    const [checkPickSlip, setCheckPickSlip] = useState(false);
    const [to, setTo] = useState('');
    const [modalError, setModalError] = useState('');

    const { data: stock, error: stockError, isValidating } = useSWR('/wp-json/wp/v2/stock', (url) => getData(url).then(r => r));
    const { data: clients, error: clientsError } = useSWR('clients', (url) => getClientList(url, 'keys').then(r => r));
    const { mutate } = useSWRConfig()

    const orderby = [
        'Racq #',
        'Client',
        'Reference',
        'Bay',
        'Design',
        'Order #',
        'Recieved',
        'SR #',
        'Type'
    ]


    useEffect(() => {

        //check if user is an admin
        if (localStorage.getItem('raqd_role') !== 'administrator') {
            navigate('/dashboard/user');
        }

        let searchData = sessionStorage.getItem('racq_search');
        if (searchData !== null) {
            searchData = JSON.parse(searchData);
            setSelectedClient(searchData.selectedClient);
            setSelectedOrder(searchData.selectedOrder);
            setSearch(searchData.search);
            setSearchBy(searchData.searchBy);
        }


    }, [])


    useEffect(() => {

        reOrder();

    }, [selectedClient, selectedOrder]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            console.log(search)
            reOrder();
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [search])

    useEffect(() => {
        if (dispatchItem !== null) {
            setVisible(true);
        }
    }, [dispatchItem])

    useEffect(() => {
        if (stock) {
            reOrder();
        }
        if (stockError) {
            console.log('stockError', stockError);
            alert('Network Error:: Please refresh or contact administrator');
        }
    }, [stock])

    useEffect(() => {
        if (clients) {
            // console.log('clients', clients);
            setClientList(['All', ...clients.sort()]);
        }
        if (clientsError) {
            console.log('clientsError', clientsError);
            alert('Network Error:: Please refresh or contact administrator');
        }
    }, [clients])


    const reOrder = () => {

        //save search to localStorage
        let searchData = {
            search: search,
            searchBy: searchBy,
            selectedClient: selectedClient,
            selectedOrder: selectedOrder,
        }

        sessionStorage.setItem('racq_search', JSON.stringify(searchData));

        let filterData = {
            search: search,
            list: stock,
            searchBy: searchBy,
            selectedClient: selectedClient,
            selectedOrder: selectedOrder,
        }



        filter(filterData).then(data => {
            setOrderedItems(data);
        }).catch(error => {
            console.log(error);
        })

        return;

    }

    const updateClient = event => {
        setSelectedClient(event.target.value);
    }

    const updateOrderby = event => {
        setSelectedOrder(event.target.value);
    }

    const updateSearch = event => {
        setSearch(event.target.value);
    }

    const updateDate = event => {
        setDate(event.target.value);
    }

    const updateAddress = event => {
        setTo(event.target.value);
    }


    const deleteItem = (item) => {
        let proceed = window.confirm('Are you sure you wish to delete racq item: ' + item.racq_number + '?');

        if (!proceed) return;

        deleteInventory(item.id).then(res => {
            console.log('deleted res', res);
            mutate('/wp-json/wp/v2/stock');
            setSelectedClient('All');
        }).catch(error => {
            console.error(error);
        })

    }

    const closeAlert = () => {
        setDispatchItem(null);
        setVisible(false);
    }

    const customAlert = item => {
        setDispatchItem(item);
        setModalError('');
    }

    const moveToDispatch = () => {

        if (dispatchItem == null) return;

        //make sure "To" field is not empty
        if (checkDispatch && to.trim() === '') {
            setModalError('please enter "To" when printing docket');
            return;
        }

        //make sure invoice number does not exist
        setDispatchLoader(true);
        getDispatchListBySearch(invoiceNumber).then(response => {

            let posts = response.data.posts;

            if (posts.length > 0) {
                setModalError('Invoice number already exists!');
                setDispatchLoader(false);
                return;
            }

            deleteInventory(dispatchItem.id).then(res => {
                addDispatchItems(dispatchItem, convertDateToNzFormat(date), invoiceNumber).then(res2 => {


                    let uri = encodeURIComponent(to);

                    if (checkDispatch && checkPickSlip) {

                        if (getBrowser() === 'Apple Safari') {
                            openWindowInMac('/docketandslip/' + res2.data.id + '/' + uri);

                        } else {
                            window.open('/docketandslip/' + res2.data.id + '/' + uri, '_blank');
                        }

                    } else if (checkDispatch) {

                        if (getBrowser() === 'Apple Safari') {
                            openWindowInMac('/print/' + res2.data.id + '/' + uri)
                        } else {
                            window.open('/print/' + res2.data.id + '/' + uri, '_blank');
                        }

                    } else if (checkPickSlip) {
                        //print pickslip
                        if (getBrowser() === 'Apple Safari') {
                            openWindowInMac('/pickslip/' + res2.data.id)
                        } else {
                            window.open('/pickslip/' + res2.data.id, '_blank');
                        }
                    }

                    setDispatchLoader(false);
                    closeAlert();
                    mutate('/wp-json/wp/v2/stock');
                }).catch(err => {
                    setDispatchLoader(false);
                    console.error(err);
                    console.log('failed to add id:' + dispatchItem.id);
                })
            }).catch(error => {
                setDispatchLoader(false);
                console.log('failed to move id:' + dispatchItem.id);
                console.error(error);
            })

        })


    }


    const checkifbarcode = (event, item) => {
        if (item.barcode.trim() === '') {
            alert('Cannot print without a barcode');
            event.preventDefault();
            return;
        }
    }

    const filter = async (data) => {

        var search = data.search;
        var list = data.list;
        var searchBy = data.searchBy;
        var selectedClient = data.selectedClient;
        var selectedOrder = data.selectedOrder;

        let promise = new Promise((resolve, reject) => {
            if (!list) {
                reject('list not found');
            }

            //Apply Client filter
            if (selectedClient != 'All' && selectedClient != '') {
                list = list.filter(x => x.client == selectedClient);
            }

            //Apply orderby
            list.sort((a, b) => {
                switch (selectedOrder) {
                    case '':
                        if (a.racq_number < b.racq_number) return -1;
                        if (a.racq_number > b.racq_number) return 1;
                        return 0;
                    case 'Racq #':
                        if (a.racq_number < b.racq_number) return -1;
                        if (a.racq_number > b.racq_number) return 1;
                        return 0;
                    case 'Client':
                        if (a.client < b.client) return -1;
                        if (a.client > b.client) return 1;
                        return 0;
                    case 'Reference':
                        if (a.reference < b.reference) return -1;
                        if (a.reference > b.reference) return 1;
                        return 0;
                    case 'Bay':
                        if (a.bay_number < b.bay_number) return -1;
                        if (a.bay_number > b.bay_number) return 1;
                        return 0;
                    case 'Recieved':
                        if (a.received < b.received) return -1;
                        if (a.received > b.received) return 1;
                        return 0;
                    case 'Order #':
                        if (a.order_number < b.order_number) return -1;
                        if (a.order_number > b.order_number) return 1;
                        return 0;
                    case 'SR #':
                        if (a.sr_number < b.sr_number) return -1;
                        if (a.sr_number > b.sr_number) return 1;
                        return 0;
                    case 'Type':
                        if (a.type < b.type) return -1;
                        if (a.type > b.type) return 1;
                        return 0;
                }
            })


            switch (searchBy.trim()) {
                case '':
                    break;
                case 'Racq #':
                    list = list.filter(x => x.racq_number.toLowerCase().includes(search.toLowerCase()))
                    break;
                case 'Client':
                    list = list.filter(x => x.client.toLowerCase().includes(search.toLowerCase()))
                    break;
                case 'Reference':
                    list = list.filter(x => x.reference.toLowerCase().includes(search.toLowerCase()) ||
                        x.barcode.toLowerCase().includes(search.toLowerCase()))
                    break;
                case 'Bay':
                    list = list.filter(x => x.bay_number.toLowerCase().includes(search.toLowerCase()))
                    break;
                case 'Recieved':
                    list = list.filter(x => x.received.toLowerCase().includes(search.toLowerCase()))
                    break;
                case 'Order #':
                    list = list.filter(x => x.order_number.toLowerCase().includes(search.toLowerCase()))
                    break;
                case 'Design':
                    list = list.filter(x => x.design.toLowerCase().includes(search.toLowerCase()))
                    break;
                case 'SR #':
                    list = list.filter(x => x.sr_number.toLowerCase().includes(search.toLowerCase()))
                    break;
                case 'Type':
                    list = list.filter(x => x.type.toLowerCase().includes(search.toLowerCase()))
                    break;
            }

            //Search by all 
            if (searchBy.trim() === 'All') {
                list = list.filter(x => {
                    if (x.racq_number?.toLowerCase()?.includes(search.toLowerCase()) ||
                        x.client?.toLowerCase()?.includes(search.toLowerCase()) ||
                        x.reference?.toLowerCase()?.includes(search.toLowerCase()) ||
                        x.barcode?.toLowerCase()?.includes(search.toLowerCase()) ||
                        x.bay_number?.toLowerCase()?.includes(search.toLowerCase()) ||
                        x.received?.toLowerCase()?.includes(search.toLowerCase()) ||
                        x.order_number?.toLowerCase()?.includes(search.toLowerCase()) ||
                        x.sr_number?.toLowerCase()?.includes(search.toLowerCase()) ||
                        x.design?.toLowerCase()?.includes(search.toLowerCase()) ||
                        x.type?.toLowerCase()?.includes(search.toLowerCase())) {
                        return true
                    } else {
                        return false;
                    }
                })

            }

            resolve(list);
        })

        return promise;

    }

    const exportCSV = () => {
        if (orderedItems && orderedItems.length > 0) {
            return [...orderedItems];
        } else {
            return [];
        }
    }

    const updateCheckDispatch = e => {

        setCheckDispatch(prev => !prev);
    }

    const updateCheckPick = e => {
        setCheckPickSlip(prev => !prev);
    }

    const generateSummary = () => {
        //console.log('summary: ', orderedItems);
        let types = {};
        for (let item of orderedItems) {
            if (types.hasOwnProperty(item.client)) {
                types[item.client].push(item.type);
            } else {
                let key = item.client;
                let value = [item.type];
                types[key] = value;
            }
        }

        let summary = [];

        for (const [key, value] of Object.entries(types)) {
            let row = {
                client: key
            }
            for (let t of value) {
                if (row.hasOwnProperty(t)) {
                    row[t] = row[t] + 1;
                } else {
                    row[t] = 1;
                }
            }
            row['total'] = value.length;
            summary.push(row);
        }

        let headers = [
            { label: 'Client', key: 'client' },
            { label: 'Carpets', key: 'carpet' },
            { label: 'Rugs', key: 'rug' },
            { label: 'Pallets', key: 'pallet' },
            { label: 'Entrance Rugs', key: 'entrance rug' },
            { label: 'Other', key: 'other' },
            { label: 'Total', key: 'total' },
        ]

        return [summary, headers];

    }


    return (
        <Container
            style={container}
        >
            <Modal
                visible={visible}
                close={closeAlert}
            >
                <div>
                    <h3>Enter Dispatch Date</h3>
                    <Textbox
                        label="Date"
                        type="date"
                        value={date}
                        onChange={updateDate}
                    />
                    <Textbox
                        label="Invoice Number"
                        type="text"
                        value={invoiceNumber}
                        onChange={e => setInvoiceNumber(e.target.value)}
                    />

                    {checkDispatch && <Textbox
                        label="To"
                        type="text"
                        value={to}
                        onChange={e => {
                            updateAddress(e);
                            setModalError('');
                        }}
                    />}

                    <Checkbox
                        label="Dispatch Docket"
                        onChange={updateCheckDispatch}
                        name="dispatch_check"
                        checked={checkDispatch}
                        data={updateCheckDispatch ? 'chk-d' : 'nchk'}
                    />


                    <Checkbox
                        label="Picking Slip"
                        onChange={updateCheckPick}
                        name="picking_check"
                        checked={checkPickSlip}
                        data={updateCheckPick ? 'chk-p' : 'nchk'}
                    />

                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '0.5rem'
                    }}
                >
                    <Button
                        btnstyle={{ marginRight: '0.5rem' }}
                        onClick={moveToDispatch}
                    >
                        Dispatch
                    </Button>
                    <Button onClick={closeAlert}>
                        Cancel
                    </Button>
                </div>
                {modalError !== '' ? <p style={{
                    color: 'var(--error-color)',
                    fontSize: '0.8rem',
                    padding: '5px'
                }}>
                    {modalError}
                </p> : null}
                <div
                    style={{
                        display: 'flex',
                    }}
                >
                    <BarLoader width="100%" color="#0571cb" loading={dispatchLoader} />
                </div>
            </Modal>
            <h2
                style={{
                    margin: '1%',
                    color: 'var(--main-color)'
                }}
            >
                Inventory
            </h2>
            <hr

                style={{
                    margin: '1%',
                }}
            />

            <div
                style={filters}
            >
                <div style={secondaryContainer}>
                    <Select
                        label="Client"
                        list={clientList}
                        id="client-filter"
                        name="client-filter"
                        value={selectedClient}
                        onChange={updateClient}
                        containerstyle={filterInput}
                    />
                    <Select
                        label="Order by"
                        list={orderby}
                        id="orderby"
                        name="orderby"
                        value={selectedOrder}
                        onChange={updateOrderby}
                        containerstyle={filterInput}
                    />
                </div>

                <div style={secondaryContainer}>
                    <Select
                        label="Search by"
                        list={['All', ...orderby]}
                        id="search-by"
                        value={searchBy}
                        onChange={event => setSearchBy(event.target.value)}
                        containerstyle={filterInput}
                    />
                    <Textbox
                        type="text"
                        label="Search"
                        id="ref-search"
                        name="ref-search"
                        value={search}
                        onChange={updateSearch}
                        txtstyle={filterInput}
                    />
                </div>


            </div>


            <div style={buttonContainer}>
                <Button
                    onClick={() => navigate('/dashboard/new')}
                    btnstyle={btn}
                >
                    Add
                </Button>

                <CSVLink data={exportCSV()} className='export-link' >Export</CSVLink>
                <CSVLink
                    data={generateSummary()[0]}
                    headers={generateSummary()[1]}
                    filename='summary-report.csv'
                    className='export-link'
                >
                    Summary
                </CSVLink>
            </div>

            <div style={count}>
                <p>Showing {orderedItems ? orderedItems.length : 0} {orderedItems && orderedItems.length == 1 ? 'item' : 'items'}</p>
                <BeatLoader size={10} color="#0571cb" loading={isValidating} />
            </div>
            <div
                style={tableContainer}
            >

                <table
                    style={table}
                >
                    <thead

                    >
                        <td style={th}>Client</td>
                        <td style={th}>Racq #</td>
                        <td style={th}>Bay #</td>
                        <td style={th}>SR #</td>
                        <td style={th}>Order #</td>
                        <td style={th}>Reference</td>
                        <td style={th}>Size</td>
                        <td style={th}>Design / Colour</td>
                        <td style={th}>Type</td>


                        {/* <td style={th}>Notes/Size (m)</td> */}
                        {/* <td style={th}>Recieved</td> */}

                    </thead>

                    <tbody>
                        {
                            orderedItems.map((item, index) => {

                                return (
                                    <tr style={index % 2 == 1 ? tr : tr2} key={index}>

                                        <td style={td}>
                                            {item.client}
                                            <div
                                                style={optionContainer}
                                            >
                                                <p onClick={() => customAlert(item)} key={index + item.racq_number} className='admin-option' >Dispatch</p>
                                                <p style={option2}>|</p>
                                                <p onClick={() => navigate('' + item.id)} key={index + item.racq_number + '2'} className='admin-option'>Edit</p>
                                                <p style={option2}>|</p>
                                                <Link onClick={(e) => checkifbarcode(e, item)} to={`/print2/${item.id}/${item.barcode}`} target="_blank" className='admin-option' >Print</Link>

                                            </div>

                                        </td>
                                        <td style={td}>{item.racq_number} </td>
                                        <td style={td}>{item.bay_number}</td>
                                        <td style={td}>{item.sr_number}</td>
                                        <td style={td}>{item.order_number}</td>
                                        <td style={td}>{item.reference}</td>
                                        <td style={td}>{item.size}</td>
                                        <td style={td}>{item.design}</td>
                                        <td style={td}>{item.type}</td>

                                        {/* <td style={td}>{item.notes}</td> */}
                                        {/* <td style={td}>{item.received}</td> */}

                                    </tr>
                                )
                            })
                        }
                    </tbody>

                </table>
            </div>
        </Container>
    );
}

const container = {
    flexDirection: 'column',
    width: '90%',
    alignSelf: 'center',
    padding: '1%',
    height: '100%',
    overflow: 'hidden',
    boxSizing: 'border-box'
}

const filters = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1%'
}

const count = {
    display: 'flex',
    flexDirection: 'row',
    padding: '1% 2% 1% 2%',
    fontSize: '0.75rem',
    fontWeight: 'bold'
}

const btn = {
    width: '100%',
    maxWidth: '200px',
    marginRight: '0.3rem'
}

const tableContainer = {
    display: 'flex',
    maxWidth: '100%',
    overflow: 'auto',
    borderRadius: '10px',
}

const table = {
    width: '100%',
    maxWidth: '100%',
    borderCollapse: 'collapse',
    borderRadius: '10px',
    backgroundColor: 'white',
    borderSpacing: '1',
    position: 'relative'
}

const th = {
    padding: '0.75rem 0 0.75rem 0.75rem',
    color: 'var(--alt-text-color)',
    backgroundColor: 'var(--main-color)',
    position: 'sticky',
    top: 0,
}

const tr = {
    cursor: 'pointer',
}

const tr2 = {
    cursor: 'pointer',
    backgroundColor: '#e2e2e2',
}

const td = {
    padding: '0.5rem'
}

const optionContainer = {
    display: 'flex',
    flexDirection: 'row',
    padding: '0.3rem 0.3rem 0.3rem 0px',
    minWidth: '150px'
}


const option2 = {
    fontSize: '0.7rem',
    marginRight: '0.3rem',
}

const filterInput = {
    padding: '0.3rem 0.3rem 0 0.3rem',
    width: '200px'
}

const buttonContainer = {
    display: 'flex',
    flexDirection: 'row',
    padding: '1%',
    columnGap: '0.5rem'
}

const secondaryContainer = {

}



export default Admin;