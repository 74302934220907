import React, { useState, useEffect } from 'react';
import '../css/UserStockList.css';
import ListItem from './ListItem';

import BarLoader from 'react-spinners/BarLoader';


const UserStockList = ({ user, list, error, search, updating }) => {



    const isContained = (item) => {
        if (!search || search.trim() === '') return true;

        for (let [key, prop] of Object.entries(item)) {

            let searchString = '' + prop;

            if (searchString.trim() !== '') {
                if (searchString?.toLowerCase()?.includes(search.toLowerCase())) return true;
            }
        }

        return false;
    }



    return (
        <div style={container} >
            {
                list && list.map((item, index) => {

                    if (isContained(item)) {
                        return (
                            <ListItem
                                data={item}
                                key={index}
                            />
                        )
                    } else {
                        return null;
                    }


                })
            }
        </div>
    );
}

const container = {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '2%',
    overflow: 'auto',
    padding: '1%',
    rowGap: '0.5rem'
}




export default UserStockList;