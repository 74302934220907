import React from 'react';
import Container from './Container';
import Radium from 'radium';

const Select = (props) => {

    const lblStyle = {
        fontSize: '15px',
        fontWeight: '450',
        marginBottom: '3px',
    }

    const container = {
        display: 'flex',
        flexDirection: 'column'
    }

    const selectStyle = {
        width: props.width ? props.width : 'auto',
        height: props.height ? props.height : 'auto',
        fontFamily: 'var(--secondary-font)',
        outline: 'none',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#ccc',
        borderRadius: '4px',
        backgroundColor: '#fafafa',
        WebkitAppearance: 'none',
        padding: '5px',
        ':hover': {
            borderColor: '#aaa'
        },
        ':focus': {
            borderColor: 'var(--main-color)'
        },


    }

    return (
        <div
            style={{ ...container, ...props.containerstyle }}
        >
            <label style={lblStyle}>{props.label}</label>
            <select style={selectStyle} name={props.name} id={props.id} {...props}>
                {props.list && props.list.map((item, index) => {
                    return (
                        <option key={index} value={item}>{item}</option>
                    )
                })}
            </select>
        </div>
    );
}



export default Radium(Select);