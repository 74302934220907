import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Auth } from '../State';

import Container from '../visual/Container';
import Card from '../visual/Card';
import Textbox from '../visual/Textbox';
import Button from '../visual/Button';
import axios from 'axios';

const Login = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const [auth, setAuth] = useRecoilState(Auth);

    let navigate = useNavigate();

    const update = (event) => {
        if (event.target.name === "username") {
            setUsername(event.target.value);
        }

        if (event.target.name === "password") {
            setPassword(event.target.value);
        }

        setError('');
    }

    const submit = (event) => {
        event.preventDefault();

        const url = "/wp-json/jwt-auth/v1/token";

        axios({
            method: 'post',
            url: url,
            data: {
                username: username,
                password: password
            },
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(response => {
            console.log("res", response.data)
            localStorage.setItem('raqd_token', response.data.token);
            localStorage.setItem('raqd_username', response.data.user_nicename);
            localStorage.setItem('raqd_company', response.data.company);


            if (response.data.role.includes('administrator')) {
                localStorage.setItem('raqd_role', 'administrator');
            } else {
                localStorage.setItem('raqd_role', 'user');
            }

            setAuth(true);
        }).catch(error => {
            const err = error;
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setError(err.response.data.message);
            }
        });

    }

    const isValidToken = async (token) => {
        const url = '/wp-json/jwt-auth/v1/token/validate';

        const body = {}

        return axios.post(url, body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }).then(res => {
            if (res.data.code == 'jwt_auth_valid_token') {
                return true;
            }
        }).catch(error => {
            console.log('error caught', error);
            return false;
        })


    }

    useEffect(() => {
        const token = localStorage.getItem('raqd_token')

        isValidToken(token).then(res => {
            if (res == true) {
                setAuth(true);
            } else {
                localStorage.removeItem('raqd_token');
                localStorage.removeItem('raqd_username');
                localStorage.removeItem('raqd_role');
                setAuth(false);
                navigate('/');
            }
        }).catch(error => {
            localStorage.removeItem('raqd_token');
            localStorage.removeItem('raqd_username');
            localStorage.removeItem('raqd_role');
            setAuth(false);
            navigate('/');
        })



    }, [])


    useEffect(() => {
        if (auth) {
            navigate('/dashboard/user');
        }
    }, [auth])


    const gotoForgotPass = () => {
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = 'https://racq.co.nz/wp-login.php?action=lostpassword';
        a.click();
        document.body.removeChild(a);
    }

    return (
        <Container style={containerStyle}>
            <Card
                width="300px"
            >
                <h2 style={{ fontWeight: 400, padding: '5px' }}>Sign In</h2>

                <form action="" onSubmit={submit}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}
                >
                    <Textbox
                        type="text"
                        label="Username"
                        name="username"
                        onChange={update}
                        value={username}
                        txtstyle={{ padding: '5px' }}
                        autoComplete="username"
                    />
                    <Textbox
                        type="password"
                        label="Password"
                        name="password"
                        onChange={update}
                        value={password}
                        autoComplete="current-password"
                        txtstyle={{ padding: '5px' }}
                    />

                    <Button
                        onClick={submit}
                        width="auto"
                        btnstyle={{
                            margin: '10px 5px 10px 5px'
                        }}
                    >
                        Sign In
                    </Button>

                    {/* when users press enter */}
                    <input style={{ display: 'none' }} type="submit" />

                    <p
                        style={{
                            fontSize: '12px',
                            alignSelf: 'center',
                            textDecoration: 'underline',
                            color: '#999',
                            marginTop: '10px',
                            marginBottom: '10px',
                            cursor: 'pointer'
                        }}
                        onClick={() => gotoForgotPass()}
                    >Forgot Password</p>
                    {error !== '' ? <p style={{
                        fontSize: '14px',
                        color: 'var(--error-color)',
                        padding: '5px',
                        cursor: 'pointer'
                    }} dangerouslySetInnerHTML={{ __html: error }}></p> : ''}
                </form>

            </Card>
        </Container>
    );
}

const containerStyle = {
    justifyContent: 'center',
    alignItems: 'center',

}

export default Login;